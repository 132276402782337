/* eslint-disable max-len */
import { commonStyleSheet } from 'library/common-styles';
import { ThemeType } from '../../theme/jss-types';

export const purchaseOrderStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        maxWidth: '100%',
        padding: '5px 8px',
        '& .ant-table-thead > tr > th': {
            padding: '0 12px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px 12px',
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        backgroundColor: theme.backgroundColor,
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-picker': {
            width: 200,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 32,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor}`,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: theme.primaryColor,
            color: '#FFFFFF',
        },
    },
    table: {
        '& td': {
            verticalAlign: 'baseline',
            border: 'none',
        },
        '& .ant-table-thead': {
            whiteSpace: 'pre-line !important',
            backgroundColor: '#',
            border: '1px solid #E8E8E8',
            color: '#262626',
            padding: 0,
        },
        '& .ant-table-selection-column': {
            borderRight: 'none !important',
        },
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    searchInput: {
        width: '100%',
        marginRight: '1%',
    },
});

export const purchaseDetailsStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 16,
            position: 'fixed',
            width: '100%',
            zIndex: 10,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-btn-link': {
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            fontSize: 12,
            color: `${theme.primaryColor} !important`,
        },
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
        },
        marginBottom: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
    },
    addText: {
        ...commonStyleSheet(theme).flexRow,
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        fontSize: 16,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    body: {
        padding: 16,
        paddingBottom: 8,
    },
    name: {
        color: '#666666',
        fontSize: 10,
        letterSpacing: 0,
        width: '25%',
    },
    value: {
        color: '#111111',
        fontSize: 12,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    box: {
        borderRadius: 4,
        backgroundColor: '#FAFAFA',
        width: '100%',
        marginBottom: 8,
    },
    fieldGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        overflowWrap: 'break-word',
        alignItems: 'start',
        fontSize: 12,
        width: '30%',
        margin: '0 10px',
    },
    fieldTitle: {
        display: 'inline-block',
        width: '50%',
        fontSize: 10,
    },
    fieldValue: {
        display: 'inline-block',
        width: '50%',
        fontWeight: 600,
        fontSize: 12,
    },
    tabDetails: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'start',
    },
    hr: {
        width: '98%',
        height: 1,
        backgroundColor: '#CCCCCC',
        marginTop: 4,
    },
    pair: {
        ...commonStyleSheet(theme).flexRow,
        marginBottom: 16,
        width: '50%',
    },
    view: {
        cursor: 'pointer',
        color: theme.primaryColor,
        fontSize: '12px',
        fontWeight: 600,
        padding: '10px',
    },
});
