import * as React from 'react';
import { purchaseOrderStyles } from './purchase-orders.styles';
import Paginator from '../common/paginator';
import {
    Table,
    Input,
    DatePicker,
    message,
    Select,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps } from '../../theme/jss-types';
import { purchaseOrderColumns } from './purchase-orders.constants';
import { ReduxStore } from '../../reducers/redux.types';
import { bindActionCreators } from 'redux';
import {
    setPagination,
} from '../../actions/generic-action';
import { Buckets, NextOrPrev, Pagination } from '../../library/Types';
import GenericHoc from '../common/generic-hoc';
import { RouteChildrenProps } from 'react-router';
import { NAVBAR_HEIGHT } from 'library/globals';
import { loadOrders } from '../../actions/purchase-actions';
import { useDebounce } from 'hooks/use-debounce';
import { Master } from 'types/master-data-types';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import PurchaseOrderDetails from './purchase-order-details';
import { getPurchaseOrderDetails } from 'network/purchase.api';
import { getChildList } from 'network/consignments.api';

interface Props
    extends StylesProps<ReturnType<typeof purchaseOrderStyles>>,
    RouteChildrenProps {
    filters: Record<string, any>;
    loading: boolean;
    loadorders: (searchString: string, dateRange?: [Moment, Moment], childCustomerCode: string) => void;
    pagination: Pagination,
    ordersList: any,
    setpagination: (bucketId: Buckets.PURCHASE_ORDER, newPagination: Partial<Pagination>) => void;
    master: Master,
}

const {
    useState,
    useEffect,
} = React;

const PurchaseOrdersPage = (props: Props) => {
    const [searchString, setSearchString] = useState<string>('');
    const [toggle, setToggle] = useState<boolean>(true);
    const [dateRange, setDateRange] = React.useState<[Moment, Moment]>([
        moment().subtract(7, 'days'),
        moment(),
    ]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState<any>({});
    const [childClients, setChildClients] = useState<any[]>([]);
    const [selectedChild, setSelectedChild] = useState<any>({});

    const { t } = useTranslation();

    const {
        classes,
        loadorders,
        setpagination,
        loading,
        pagination,
        ordersList,
        master,
    } = props;

    const debounceTimeSearchString = useDebounce(searchString);
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;

    const loadAccounts = async () => {
        setIsLoading(true);
        const result = await getChildList();
        setChildClients(result?.data || []);
        const customer = result?.data?.length ? result.data[0] : undefined;
        setSelectedChild(customer);
        setIsLoading(false);
    };

    useEffect(() => {
        loadAccounts();
    }, []);

    useEffect(() => {
        loadorders(searchString, dateRange, selectedChild?.customer_code);
    }, [debounceTimeSearchString, toggle, dateRange, selectedChild]);

    const renderText = (text: string | number) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const getOrderDetails = async (id: string) => {
        setIsLoading(true);
        const response = await getPurchaseOrderDetails({
            referenceNumber: id,
        });
        if (response.isSuccess) {
            setSelectedOrderDetails(response.data);
            setShowDetailsModal(true);
        } else {
            message.error(response.errorMessage);
        }
        setIsLoading(false);
    };

    const renderReferenceNumber = (text: string) => {
        return (
            <div
                className={classes.cellValue}
                style={{ cursor: 'pointer' }}
                onClick={() => getOrderDetails(text)}
            >
                {text}
            </div>
        );
    };

    const renderColumn = (text: any, column: string) => {
        if (typeof text === 'boolean') {
            return renderText(text ? 'Yes' : 'No');
        }
        if (!text || text === null) {
            return <div className={classes.cellNa}>—</div>;
        }
        if (column === 'reference_number') {
            return renderReferenceNumber(text);
        }
        return renderText(text);
    };

    const getColumns = (): any[] => {
        const columns: any = Object.keys(purchaseOrderColumns).map((column: string) => {
            return {
                key: column,
                title: purchaseOrderColumns[column],
                dataIndex: column,
                ellipsis: true,
                render: (text: string) => renderColumn(text, column),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading || isLoading}
                rowKey={(row) => row.id}
                columns={getColumns()}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                className={classes.table}
                dataSource={ordersList?.data || []}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 110px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    const handleSearchQuery = (e: any) => {
        const newPagination = {
            ...pagination,
            currentPageNumber: 1,
        };
        setpagination(Buckets.PURCHASE_ORDER, newPagination);
        setSearchString(e.target.value);
    };


    const handleDateChange = (obj: any) => {
        setDateRange(obj);
    };

    const hideDetailsModal = () => {
        setShowDetailsModal(false);
        setSelectedOrderDetails({});
    };

    const renderDatePicker = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    Created At
                </span>
                <DatePicker.RangePicker
                    allowEmpty={[false, false]}
                    allowClear={false}
                    value={dateRange}
                    onChange={handleDateChange}
                />
            </div>
        );
    };


    const renderSearch = () => {
        return (
            <div className={classes.filter}>
                <Input
                    className={classes.searchInput}
                    type="search"
                    value={searchString}
                    onChange={(e: any) => handleSearchQuery(e)}
                    placeholder={t('Search')}
                    suffix={<SearchOutlined />}
                />
            </div>
        );
    };

    const renderCustomer = () => {
        if (!childClients.length) {
            return null;
        }
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    Customer
                </span>
                <Select
                    style={{ width: 200, marginRight: 10 }}
                    placeholder="Select a customer"
                    value={selectedChild?.customer_code}
                    onChange={(value: any) => {
                        const customer = childClients.find((client) => client.customer_code === value);
                        setSelectedChild(customer);
                    }}
                    options={childClients.map((client) => ({
                        label: client.customer_name,
                        value: client.customer_code,
                    }))}
                />
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderCustomer()}
                {renderSearch()}
                {renderDatePicker()}
            </div>
        );
    };


    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setpagination(Buckets.PURCHASE_ORDER, newPagination);
        setToggle(!toggle);
    };

    const renderPaginator = () => {
        return (
            <div style={{ direction: 'ltr' }}>
                <Paginator
                    currentPageNumber={pagination.currentPageNumber}
                    isNextPresent={ordersList?.isNextPresent}
                    onNextClick={() => handlePagination('next')}
                    onPrevClick={() => handlePagination('prev')}
                />
            </div>
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderPaginator()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                <div className={classes.extraFilters}>
                    {renderLeftFilters()}
                    {renderRightFilters()}
                </div>
            </div>
        );
    };

    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
            {showDetailsModal && (
                <PurchaseOrderDetails
                    onClose={hideDetailsModal}
                    data={selectedOrderDetails}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const { PURCHASE_ORDER } = generic;
    const {
        loading,
        data,
        pagination,
    } = PURCHASE_ORDER;
    return {
        loading,
        ordersList: data,
        pagination,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        loadorders: loadOrders,
        setpagination: setPagination,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: purchaseOrderStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const PurchaseOrders = GenericHoc(hocConfig)(PurchaseOrdersPage);
export default PurchaseOrders;
