import { FINANCE_SERVICE_BACKEND_URL } from 'library/globals';
import { GET, POST } from './api-hander.service';
import {
    FETCH_CUSTOMER_WALLET_DATA,
    WALLET_LEDGER,
    BLOCKED_AMOUNT_LEDGER,
    WALLET_LEDGER_DOWNLOAD,
    RECHARGE_TRANSACTIONS,
    BLOCKED_WALLET_LEDGER_DOWNLOAD,
    FETCH_PAYPHI_TRANSACTION_AND_URL,
    FETCH_PAYMENT_STATUS,
} from './api.constants';

export const fetchWalletLedger = async (params: any) => {
    return POST(`${FINANCE_SERVICE_BACKEND_URL}${WALLET_LEDGER}`, params);
};

export const fetchRechargeTransactions = async (params: any) => {
    return POST(`${FINANCE_SERVICE_BACKEND_URL}${RECHARGE_TRANSACTIONS}`, params);
};

export const fetchCustomerWalletData = async () => {
    return GET(`${FINANCE_SERVICE_BACKEND_URL}${FETCH_CUSTOMER_WALLET_DATA}`, {});
};

export const fetchPaymentStatus = async (params: any) => {
    return POST(`${FINANCE_SERVICE_BACKEND_URL}${FETCH_PAYMENT_STATUS}`, params);
};

export const getRedirectionURL = async (params: any) => {
    return POST(`${FINANCE_SERVICE_BACKEND_URL}${FETCH_PAYPHI_TRANSACTION_AND_URL}`, params);
};

export const fetchWalletBlockedAmount = async (params: any) => {
    return POST(`${FINANCE_SERVICE_BACKEND_URL}${BLOCKED_AMOUNT_LEDGER}`, params);
};

export const downloadWalletLedger = async (params: any, isBlob: boolean = true) => {
    return POST(`${FINANCE_SERVICE_BACKEND_URL}${WALLET_LEDGER_DOWNLOAD}`, params, isBlob);
};

export const downloadBlockedWalletLedger = async (params: any, isBlob: boolean = true) => {
    return POST(`${FINANCE_SERVICE_BACKEND_URL}${BLOCKED_WALLET_LEDGER_DOWNLOAD}`, params, isBlob);
};
