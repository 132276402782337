import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Radio,
} from 'antd';

const styles = () => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
}

const IsCODField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        form,
    } = props;
    const {
        ConsignmentCategory,
        IsCOD,
    } = formFields;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);

    if (consignmentCategory === 'international') return <></>;

    return (
        <Col span={12}>
            <div>
                <div
                    className={classes.title}
                    style={{ width: '100%', marginBottom: '5px' }}
                >
                    {t(IsCOD.label)}
                </div>
                <Form.Item
                    name={IsCOD.key}
                    valuePropName={IsCOD.valuePropName}
                    initialValue={IsCOD.defaultValue}
                >
                    <Radio.Group
                        options={IsCOD.options?.map((option) => {
                            return {
                                label: t(option.label),
                                value: option.value,
                            };
                        })}
                        defaultValue={IsCOD.defaultValue}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = () => {
    return {};
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(IsCODField);
