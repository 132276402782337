import { StylesProps } from '../../../theme/jss-types';
import { summaryStepStyles } from '../single-consignment.styles';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import * as React from 'react';
import { CurrentPage } from '../create-consignment.types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { get } from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import { Tooltip, Collapse } from 'antd';
import i18n from 'i18n';
import { snakeToPretty } from 'library/lodash-helper';

const customLabels = {
    consigneeCompanyName: 'consignee_company_name',
    receiverVATNumber: 'receiver_vat_number',
    destinationType: 'consignee_type',
    consigneeCode: 'consignee_code',
    isCOD: 'is_cod',
    serviceType: 'service_type',
    srcAddress: 'Source Address',
    dstAddress: 'Delivery Address',
    boxDetails: 'Box Details',
    billAddress: 'Billing Address',
};

interface SummaryProps extends StylesProps<ReturnType<typeof summaryStepStyles>> {
    formData: any;
    handleTabChange: (tab: CurrentPage) => void;
    sections: any;
    showDeliveryType: boolean;
    isCopApplicable: boolean;
    showEwb: boolean;
    pickupScheduleCreation: boolean;
    pieceLayout: any;
}

const SummaryStep = (props: SummaryProps) => {
    const {
        classes,
        formData,
        handleTabChange,
        sections,
        showDeliveryType,
        isCopApplicable,
        showEwb,
        pickupScheduleCreation,
        pieceLayout,
    } = props;

    const hideFields = {
        typeOfDelivery: !showDeliveryType,
        retailTransactionType: !isCopApplicable,
        cashonpickup: !isCopApplicable,
        ewbNumber: !showEwb,
        ewbDate: !showEwb,
        kiranaHubCode: !showEwb,
        pickupSchedule: !pickupScheduleCreation,
    };
    const { t } = useTranslation();
    const [data, setData] = React.useState<any>(formData);
    React.useEffect(() => {
        function unflattenObject(flatObj: any) {
            const unflatten = (obj: any) => {
                const result = {};
                const objKeys = Object.keys(obj);
                objKeys.forEach((key) => {
                    const keys = key.split('.');
                    let nestedObj: any = result;
                    for (let i = 0; i < keys.length - 1; i += 1) {
                        const nestedKey = keys[i];
                        if (!(nestedKey in nestedObj)) {
                            nestedObj[nestedKey] = {};
                        }
                        nestedObj = nestedObj[nestedKey];
                    }
                    nestedObj[keys[keys.length - 1]] = obj[key];
                });
                return result;
            };

            return unflatten(flatObj);
        }
        setData(unflattenObject(formData));
    }, [formData]);

    const renderAddress = (addressType: any) => {
        const address = data[addressType];
        return (
            <>
                <div key={`${addressType}`} style={{ width: '50%', paddingRight: '15px' }}>
                    <div key={`${addressType}-heading`} style={{ fontSize: '12px' }}>
                        {t(customLabels[addressType as keyof typeof customLabels] || addressType)}
                    </div>
                    {
                        address ? (
                            <>
                                <div style={{ fontWeight: 600, fontSize: '13px' }}>{address.name || ''}</div>
                                <div style={{ fontWeight: 600, fontSize: '13px' }}>
                                    {`${address.addressLine1
                                        ? `${address.addressLine1}, ` : ''} ${address.addressLine2 || ''}`}
                                </div>
                                <div style={{ fontWeight: 600, fontSize: '13px' }}>
                                    {`${address.cityName
                                        ? `${address.cityName} - ` : ''} ${address.pincode || ''}`}
                                    {`${address.stateName
                                        ? `${address.stateName}, ` : ''} ${address.countryName || ''}`}
                                </div>
                                <div style={{ fontWeight: 600, fontSize: '13px' }}>{address.phone || ''}</div>
                            </>
                        ) : '-'
                    }
                </div>
            </>
        );
    };

    const renderConsignorDetails = () => {
        return renderAddress('srcAddress');
    };

    const renderConsigneeDetails = () => {
        const { redirectionAddress } = data;
        return (
            <>
                {renderAddress('dstAddress')}
                {
                    redirectionAddress ? renderAddress('redirectionAddress') : null
                }
            </>
        );
    };

    const renderAddressField = () => {
        return (
            <>
                {renderConsignorDetails()}
                {renderConsigneeDetails()}
            </>
        );
    };

    const renderDimensions = () => {
        return (
            <>
                <div key="dimensions.length" style={{ width: '25%' }}>
                    <div key="dimensions.length-heading" style={{ fontSize: '12px' }}>
                        {t(customLabels['length' as keyof typeof customLabels] || 'length')}
                    </div>
                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                        {data.dimensions?.length ? `${data.dimensions.length} ${data.dimensionUnit}` : '-'}
                    </div>
                </div>
                <div key="dimensions.width" style={{ width: '25%' }}>
                    <div key="dimensions.width-heading" style={{ fontSize: '12px' }}>
                        {t(customLabels['width' as keyof typeof customLabels] || 'width')}
                    </div>
                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                        {data.dimensions?.width ? `${data.dimensions.width} ${data.dimensionUnit}` : '-'}
                    </div>
                </div>
                <div key="dimensions.height" style={{ width: '25%' }}>
                    <div key="dimensions.height-heading" style={{ fontSize: '12px' }}>
                        {t(customLabels['height' as keyof typeof customLabels] || 'height')}
                    </div>
                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                        {data.dimensions?.height ? `${data.dimensions.height} ${data.dimensionUnit}` : '-'}
                    </div>
                </div>
            </>
        );
    };

    const renderDimensionsV1 = () => {
        const pieces = Object.keys(data.piecesDetail)?.map((key) => {
            return {
                ...data.piecesDetail[key],
                id: key,
            };
        });
        if (data.courierType === 'document' || data.courierType === 'envelope') {
            return (
                <>
                    <div key="weight" style={{ width: '25%' }}>
                        <div key="weight-heading" style={{ fontSize: '12px' }}>
                            {t(customLabels['weight' as keyof typeof customLabels] || 'weight')}
                        </div>
                        <div style={{ fontWeight: 600, fontSize: '13px' }}>
                            {data.weight ? `${data.weight} ${data.weightUnit}` : '-'}
                        </div>
                    </div>
                    <div key="description" style={{ width: '25%' }}>
                        <div key="description-heading" style={{ fontSize: '12px' }}>
                            {t(customLabels['description' as keyof typeof customLabels] || 'description')}
                        </div>
                        <div style={{ fontWeight: 600, fontSize: '13px' }}>
                            {data.description || '-'}
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <div key="quantity" style={{ width: '25%' }}>
                    <div key="quantity-heading" style={{ fontSize: '12px' }}>
                        {t(customLabels['quantity' as keyof typeof customLabels] || 'quantity')}
                    </div>
                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                        {pieces?.length || '-'}
                    </div>
                </div>
                <div key="weight" style={{ width: '25%' }}>
                    <div key="weight-heading" style={{ fontSize: '12px' }}>
                        {t(customLabels['weight' as keyof typeof customLabels] || 'weight')}
                    </div>
                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                        {data.weight ? `${data.weight} ${data.weightUnit}` : '-'}
                    </div>
                </div>
                <div key="description" style={{ width: '25%' }}>
                    <div key="description-heading" style={{ fontSize: '12px' }}>
                        {t(customLabels['description' as keyof typeof customLabels] || 'description')}
                    </div>
                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                        {data.description || '-'}
                    </div>
                </div>
                {
                    pieces.map((piece: any) => {
                        return (
                            <div key={piece.id} style={{ display: 'flex', width: '100%' }}>
                                <div key={`${piece.id}-length`} style={{ width: '25%' }}>
                                    <div key={`${piece.id}-heading`} style={{ fontSize: '12px' }}>
                                        {t(customLabels['length' as keyof typeof customLabels] || 'length')}
                                    </div>
                                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                                        {piece.length ? `${piece.length} ${piece.dimensionsUnit}` : '-'}
                                    </div>
                                </div>
                                <div key={`${piece.id}-width`} style={{ width: '25%' }}>
                                    <div key={`${piece.id}-heading`} style={{ fontSize: '12px' }}>
                                        {t(customLabels['width' as keyof typeof customLabels] || 'width')}
                                    </div>
                                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                                        {piece.width ? `${piece.width} ${piece.dimensionsUnit}` : '-'}
                                    </div>
                                </div>
                                <div key={`${piece.id}-height`} style={{ width: '25%' }}>
                                    <div key={`${piece.id}-heading`} style={{ fontSize: '12px' }}>
                                        {t(customLabels['height' as keyof typeof customLabels] || 'height')}
                                    </div>
                                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                                        {piece.height ? `${piece.height} ${piece.dimensionsUnit}` : '-'}
                                    </div>
                                </div>
                                <div key={`${piece.id}-weight`} style={{ width: '25%' }}>
                                    <div key={`${piece.id}-heading`} style={{ fontSize: '12px' }}>
                                        {t(customLabels['weight' as keyof typeof customLabels] || 'weight')}
                                    </div>
                                    <div style={{ fontWeight: 600, fontSize: '13px' }}>
                                        {piece.weight ? `${piece.weight} ${piece.weightUnit}` : '-'}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </>
        );
    };

    const renderPickupSchedule = () => {
        return (
            <div key="pickupSchedule" style={{ width: '25%' }}>
                <div key="pickupSchedule-heading" style={{ fontSize: '12px' }}>
                    {t(customLabels['pickupSchedule' as keyof typeof customLabels] || 'pickupSchedule')}
                </div>
                <div style={{ fontWeight: 600, fontSize: '13px' }}>
                    {moment(data.pickupSchedule).format('YYYY-MM-DD') || '-'}
                </div>
            </div>
        );
    };

    const renderPieceField = (idx: any, key: string | undefined) => {
        if (!key) return null;
        const dataPair = {
            label: key,
            value: data.piecesDetail[idx][key],
        };
        switch (key) {
            case 'length':
            case 'width':
            case 'height':
                dataPair.value = dataPair.value
                    ? `${dataPair.value}  ${data.piecesDetail[idx]?.dimensionsUnit || 'cm'}` : '-';
                break;
            case 'declaredValue':
                dataPair.value = dataPair.value
                    ? `${dataPair.value}  ${data.piecesDetail[idx]?.declaredCurrency || ''}` : '-';
                break;
            case 'weight':
                dataPair.value = dataPair.value ? `${dataPair.value}  ${data.piecesDetail[idx]?.weightUnit || ''}` : '-';
                break;
            case 'reference_number': {
                const refNumber = data.piecesDetail[idx][key];
                dataPair.label = 'pieceReferenceNumber';
                dataPair.value = (
                    <Tooltip title={refNumber}>
                        {refNumber?.length > 12 ? `${refNumber.slice(0, 12)}...` : refNumber}
                    </Tooltip>
                );
                break;
            }
            default:
                break;
        }
        return (
            <div className={classes.fieldGroup} key={dataPair.label}>
                <div className={classes.fieldTitle}>
                    {i18n.exists(dataPair.label) ? t(dataPair.label) : snakeToPretty(dataPair.label)}
                </div>
                <div className={classes.fieldValue}>
                    {dataPair.value || '-'}
                </div>
            </div>
        );
    };

    const renderPieceDetails = (piece: any, idx: number) => {
        const pieceKeys = pieceLayout.filter((key: any) => [
            'reference_number',
            'description',
            'length',
            'width',
            'height',
            'weight',
            'unit',
            'itemUnit',
            'quantity',
            'codAmount',
            'declaredValue',
        ].includes(key));
        return (
            <div style={{ width: '100%' }}>
                <Collapse expandIconPosition="right" bordered={false} ghost>
                    <Collapse.Panel
                        className={classes.collapse}
                        key={piece.id}
                        header={`${data.is_international ? 'Box' : t('Piece')} ${idx + 1}`}
                    >
                        <div className={classes.pieceTabDetails}>
                            {
                                pieceKeys.map((key: any) => renderPieceField(idx, key))
                            }
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    };


    const renderPieces = () => {
        return data?.piecesDetail?.map((piece: any, idx: number) => {
            return renderPieceDetails(piece, idx);
        });
    };

    const renderDeclaredValue = () => {
        const value = data.declaredValue || data.declaredPrice;
        const currency = data.declaredCurrency || '';
        return (
            <div key="declaredValue" style={{ width: '25%' }}>
                <div key="declaredValue-heading" style={{ fontSize: '12px' }}>
                    {t(customLabels['declaredValue' as keyof typeof customLabels] || 'declaredValue')}
                </div>
                <div style={{ fontWeight: 600, fontSize: '13px' }}>
                    {value ? `${value} ${currency}` : '-'}
                </div>
            </div>
        );
    };

    const renderPickupTime = () => {
        const { pickupTimeSlotStart, pickupTimeSlotEnd } = data;
        return (
            <div key="pickupTime" style={{ width: '25%' }}>
                <div key="pickupTime-heading" style={{ fontSize: '12px' }}>
                    {t(customLabels['pickupTime' as keyof typeof customLabels] || 'pickupTime')}
                </div>
                <div style={{ fontWeight: 600, fontSize: '13px' }}>
                    {pickupTimeSlotStart && pickupTimeSlotEnd ? `${pickupTimeSlotStart} - ${pickupTimeSlotEnd}` : '-'}
                </div>
            </div>
        );
    };

    const renderCodAmount = () => {
        const value = data.codAmount;
        const currency = data.currency || '';
        return (
            <div key="codAmount" style={{ width: '25%' }}>
                <div key="codAmount-heading" style={{ fontSize: '12px' }}>
                    {t(customLabels['codAmount' as keyof typeof customLabels] || 'codAmount')}
                </div>
                <div style={{ fontWeight: 600, fontSize: '13px' }}>
                    {value ? `${value} ${currency}` : '-'}
                </div>
            </div>
        );
    };

    const renderWeight = () => {
        const weightUnit = data.weightUnit || '';
        return (
            <div key="weight" style={{ width: '25%' }}>
                <div key="weight-heading" style={{ fontSize: '12px' }}>
                    {t(customLabels['weight' as keyof typeof customLabels] || 'weight')}
                </div>
                <div style={{ fontWeight: 600, fontSize: '13px' }}>
                    {data.weight ? `${data.weight} ${weightUnit}` : '-'}
                </div>
            </div>
        );
    };

    const renderAccount = () => {
        return (
            <div key="childClient" style={{ width: '25%' }}>
                <div key="childClient-heading" style={{ fontSize: '12px' }}>
                    {t(customLabels['childClient' as keyof typeof customLabels] || 'childClient')}
                </div>
                <div style={{ fontWeight: 600, fontSize: '13px' }}>
                    {data.childClient && data.childClient.split('+')[2] ? data.childClient.split('+')[2] : '-'}
                </div>
            </div>
        );
    };

    const renderField = (field: any) => {
        if (hideFields[field]) return null;
        switch (field) {
            case 'address':
                return renderAddressField();
            case 'consignorDetails':
                return renderConsignorDetails();
            case 'consigneeDetails':
                return renderConsigneeDetails();
            case 'srcAddress':
                return renderAddress('srcAddress');
            case 'dstAddress':
                return renderAddress('dstAddress');
            case 'redirectionAddress':
                return renderAddress('redirectionAddress');
            case 'alternateDelAddress1':
                return renderAddress('alternateDelAddress1');
            case 'alternateDelAddress2':
                return renderAddress('alternateDelAddress2');
            case 'billToDetails':
                return renderAddress('billAddress');
            case 'weight':
                return renderWeight();
            case 'dimensions':
                return renderDimensions();
            case 'dimensionsV1':
                return renderDimensionsV1();
            case 'pickupSchedule':
                return renderPickupSchedule();
            case 'declaredValue':
                return renderDeclaredValue();
            case 'codAmount':
                return renderCodAmount();
            case 'pickupTime':
                return renderPickupTime();
            case 'childClient':
                return renderAccount();
            case 'piece':
                return null;
            default:
                return (
                    <div key={field} style={{ width: '25%' }}>
                        <div key={`${field}-heading`} style={{ fontSize: '12px' }}>
                            {t(customLabels[field as keyof typeof customLabels] || field)}
                        </div>
                        <div key={`${field}-value`} style={{ fontWeight: 600, fontSize: '13px' }}>
                            {t(data[field]) || '-'}
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className={classes.main}>
            {sections?.map((section: any) => {
                return (
                    <div key={section.prettyName} className={classes.section}>
                        <div className={classes.sectionHeading}>
                            {section.icon ? <section.icon style={{ fontSize: '28px' }} /> : null}
                            <div>{section.prettyName}</div>
                        </div>
                        <div className={classes.fields}>
                            {section.fieldsList?.map((field: any) => {
                                return (
                                    renderField(field)
                                );
                            })}
                            {
                                section.fieldsList?.includes('piece') ? (
                                    renderPieces()
                                ) : null
                            }
                        </div>
                        <div
                            onClick={() => handleTabChange(section.goTo)}
                            className={classes.edit}
                        >
                            <EditOutlined />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show, config, properties } = master;
    const show_delivery_type_in_customerportal = get(parts_to_show, 'show_delivery_type_in_customerportal', false);
    const is_cop_applicable = get(parts_to_show, 'is_cop_applicable', false);
    const showEwb = get(config, 'config.customer_portal_config.show_ewb_fields', false);
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const pieceLayout = formSubLayout?.piece || [];
    return {
        showDeliveryType: show_delivery_type_in_customerportal,
        isCopApplicable: is_cop_applicable,
        showEwb,
        pickupScheduleCreation: properties?.pickup_schedule_creation,
        pieceLayout,
    };
};


const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: summaryStepStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
};

export default GenericHoc(hocConfig)(SummaryStep);
