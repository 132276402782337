import * as React from 'react';
import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps } from '../../theme/jss-types';
import { consignmentDetailedStyles } from './consignment-details.styles';
import ConsignmentTracking from './consignment-tracking';
import {
    // prettyMovementTypes,
    prettyStatusNames,
} from './consignments.constants';
import Loader from '../common/Loader';
import GenericHoc from '../common/generic-hoc';
import { snakeToPretty } from '../../library/lodash-helper';
import {
    Dropdown,
    Menu,
    message,
    Spin,
} from 'antd';
import Helper from 'library/Helper';
import {
    downloadConsignments4X6Files,
    fetchConsignment,
    printConsignment,
    printConsignment4x6,
    printConsignment4x6Domestic,
    printInvoice,
    printPodShippingLabel,
    printRoutingLabel,
    printAddressLabel,
} from '../../network/consignments.api';
import { Master } from 'types/master-data-types';
import ConsignmentDetailsInternational from './consignment-details-international';
import { useTranslation } from 'react-i18next';

interface ConsignmentDetailedProps extends StylesProps<ReturnType<typeof consignmentDetailedStyles>> {
    handleGoback: () => void;
    referenceNumber: string;
    master: Master;
}

const ConsignmentDetailed = (props: ConsignmentDetailedProps) => {
    const {
        classes, handleGoback, referenceNumber, master,
    } = props;
    const customerProperties = master?.properties;
    const { t } = useTranslation();
    const [consignment, setConsignment] = React.useState<any>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const [printing, setPrinting] = React.useState(false);
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    let showShipmentLabelA4 = true;
    let showShippingLabelA6 = true;
    let showshippingLabelPod = true;
    let showShippingLabel4X6 = true;
    let showAddressLabelA4 = true;
    let showAddressLabel4X2 = true;
    let showRoutingLabelA4 = true;
    let showRoutingLabel4x4 = true;
    let restrictPrintInvoice = false;
    let useThirdPartyLabel = false;
    if (customerProperties && enable_customer_config) {
        showShipmentLabelA4 = customerProperties?.a4_shipping_label;
        showShippingLabelA6 = customerProperties?.a6_shipping_label;
        showshippingLabelPod = customerProperties?.shipping_label_pod;
        showShippingLabel4X6 = customerProperties?.x46_shipping_label;
        showAddressLabelA4 = customerProperties?.a4_address_label;
        showAddressLabel4X2 = customerProperties?.x42_address_label;
        showRoutingLabelA4 = customerProperties?.a4_routing_label;
        showRoutingLabel4x4 = customerProperties?.x44_routing_label;
        restrictPrintInvoice = !customerProperties?.print_invoice;
        useThirdPartyLabel = customerProperties?.use_third_party_label;
    }

    const showShippingLabelButton = showShipmentLabelA4 || showShippingLabelA6
    || showshippingLabelPod || showShippingLabel4X6;

    /*
    const allowMultiplePieceDimensions = master?.config?.customer_portal_consignment_config?.allow_multiple_piece_dimensions;
    const showNewDetailedView = master?.config?.customer_portal_config?.show_new_detailed_view;
    */

    const loadDetails = async () => {
        setLoading(true);
        const response = await fetchConsignment({ reference_number: referenceNumber });
        setConsignment(response?.data?.length ? response?.data[0] : {});
        setLoading(false);
    };

    React.useEffect(() => {
        loadDetails();
    }, []);

    const renderBack = () => {
        return (
            <div
                className={classes.backText}
                onClick={handleGoback}
            >
                <ArrowLeftOutlined
                    className={classes.arrowIcon}
                />
                {t('back_to_all_consignments')}
            </div>
        );
    };

    const renderReferenceItem = (name: string, value: string) => {
        return (
            <div className={classes.flexColumn}>
                <span className={classes.referenceValue}>{value}</span>
                <span className={classes.referenceName}>{name}</span>
            </div>
        );
    };

    const menuStyle: React.CSSProperties = {
        padding: 12,
        borderBottom: '1px solid #CCCCCC',
        fontWeight: 'bold',
        fontSize: 12,
    };

    const handlePrintLabel = (fileBuffer: any, fileName: string) => {
        if (fileBuffer.isSuccess) {
            const name = fileBuffer.filename || fileName;
            Helper.downloadFileData(
                fileBuffer.data,
                name,
                true,
                'pdf',
            );
            message.success('Success');
        } else {
            message.error('Unable to fetch label at the moment. Please try again after sometime');
        }
        setPrinting(false);
    };

    const handlePrintInvoiceBuffer = (fileBuffer: any, fileName: string) => {
        if (fileBuffer.isSuccess) {
            const name = fileBuffer.filename || fileName;
            Helper.downloadFileData(
                fileBuffer.data,
                name,
                true,
                'pdf',
            );
            message.success('Success');
        } else {
            message.error('Unable to fetch Invoice at the moment. Please try again after sometime');
        }
        setPrinting(false);
    };

    const handlePrint = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        setPrinting(true);
        const fileBuffer = await printConsignment({
            isSmall,
            referenceNumbers,
            useThirdPartyLabel,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handlePrint4X6 = async (referenceNumbers: any) => {
        setPrinting(true);
        const fileUrls = await printConsignment4x6({
            referenceNumbers,
        });
        fileUrls?.data?.forEach((fileUrlsList: any) => {
            fileUrlsList?.forEach(async (fileUrl: any) => {
                if (fileUrl && fileUrl.length) {
                    const fileBuffer = await downloadConsignments4X6Files(fileUrl);
                    const fileUrlSplit = fileUrl.split('/');
                    handlePrintLabel(fileBuffer, fileUrlSplit[fileUrlSplit.length - 1]);
                }
            });
        });
        setPrinting(false);
    };

    const handlePrint4X6Domestic = async (referenceNumbers: any) => {
        setPrinting(true);
        const fileBuffer = await printConsignment4x6Domestic({
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, `Shipping_Label_4x6_${referenceNumbers[0]}.pdf`);
    };

    const handleOnClickPrint4X6 = async (referenceNumbers: any, isInternational: boolean) => {
        if (isInternational) {
            await handlePrint4X6(referenceNumbers);
        } else {
            await handlePrint4X6Domestic(referenceNumbers);
        }
    };

    const handlePrintPod = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        setPrinting(true);
        const fileBuffer = await printPodShippingLabel({
            isSmall,
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handlePrintRouting = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        setPrinting(true);
        const fileBuffer = await printRoutingLabel({
            isSmall,
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handlePrintAddress = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        setPrinting(true);
        const fileBuffer = await printAddressLabel({
            small: isSmall,
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handlePrintInvoice = async (referenceNumbers: any, fileName: string) => {
        setPrinting(true);
        const fileBuffer = await printInvoice({
            referenceNumbers,
        });
        handlePrintInvoiceBuffer(fileBuffer, fileName);
    };

    const renderPrintInvoice = (row: any) => {
        if (
            !row.is_international
            || !master.Customer.show_invoice_options
            || row.load_type === 'DOCUMENT'
            || restrictPrintInvoice
        ) {
            return null;
        }
        return (
            <Menu.Item
                style={menuStyle}
                onClick={() => handlePrintInvoice(
                    [row.reference_number],
                    `Invoice_${row.reference_number}.pdf`,
                )}
            >
                {t('print_invoice')}
            </Menu.Item>
        );
    };

    const renderPrintOptions = (row: any) => {
        return (
            <Menu>
                <Menu.Item
                    style={{ ...menuStyle, padding: 0 }}
                >
                    {showShippingLabelButton && (
                        <Menu.SubMenu
                            title={(
                                <span
                                    style={{
                                        ...menuStyle,
                                        padding: 0,
                                        border: 'none',
                                    }}
                                >
                                    {t('shipping_label')}
                                </span>
                            )}
                            style={{
                                ...menuStyle,
                                padding: '6px 0px',
                                border: 'none',
                            }}
                        >
                            {showShipmentLabelA4 && (
                                <Menu.Item
                                    style={menuStyle}
                                    onClick={() => handlePrint(
                                        [row.reference_number],
                                        false,
                                        `Shipping_Label_${row.reference_number}.pdf`,
                                    )}
                                >
                                    {t('label_a4')}
                                </Menu.Item>
                            )}
                            {showShippingLabelA6 && (
                                <Menu.Item
                                    style={menuStyle}
                                    onClick={() => handlePrint(
                                        [row.reference_number],
                                        true,
                                        `Shipping_Label_${row.reference_number}.pdf`,
                                    )}
                                >
                                    A6
                                </Menu.Item>
                            )}
                            {showShippingLabel4X6 && (
                                <Menu.Item
                                    style={menuStyle}
                                    onClick={() => handleOnClickPrint4X6([row.reference_number], row.is_international)}
                                >
                                    4X6
                                </Menu.Item>
                            )}
                        </Menu.SubMenu>
                    )}
                </Menu.Item>
                {showshippingLabelPod && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrintPod(
                            [row.reference_number],
                            false,
                            `Shipping_Label_POD_${row.reference_number}.pdf`,
                        )}
                    >
                        {t('shipping_label_pod')}
                    </Menu.Item>
                )}
                { showRoutingLabel4x4 && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrintRouting(
                            [row.reference_number],
                            false,
                            `Routing_Label_${row.reference_number}.pdf`,
                        )}
                    >
                        {t('routing_label')}
                        4*4
                    </Menu.Item>
                )}
                {showRoutingLabelA4 && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrintRouting(
                            [row.reference_number],
                            false,
                            `Routing_Label_${row.reference_number}.pdf`,
                        )}
                    >
                        {t('routing_label')}
                        {t('label_a4')}
                    </Menu.Item>
                )}
                { showAddressLabel4X2 && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrintAddress(
                            [row.reference_number],
                            true,
                            `Address_Label_${row.reference_number}.pdf`,
                        )}
                    >
                        {t('address_label')}
                        4*2
                    </Menu.Item>
                )}
                { showAddressLabelA4 && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrintAddress(
                            [row.reference_number],
                            false,
                            `Address_Label_${row.reference_number}.pdf`,
                        )}
                    >
                        {t('address_label')}
                        A4
                    </Menu.Item>
                )}
                {renderPrintInvoice(row)}
            </Menu>
        );
    };

    const renderPrint = () => {
        if (printing) {
            return <Spin />;
        }
        return !useThirdPartyLabel ? (
            <Dropdown
                disabled={printing}
                overlay={renderPrintOptions(consignment)}
            >
                <PrinterOutlined />
            </Dropdown>
        ) : (
            <PrinterOutlined
                onClick={() => {
                    handlePrint(
                        [consignment.reference_number],
                        false,
                        `Shipping_Label_${consignment.reference_number}.pdf`,
                    );
                }}
            />
        );
    };

    const renderReference = () => {
        if (loading) {
            return (
                <Loader zIndex={5} />
            );
        }
        return (
            <div className={classes.referenceBox}>
                {renderReferenceItem(t('cn_reference_no'), consignment.reference_number)}
                {renderReferenceItem(t('customer_reference_no'), consignment.customer_reference_number || '-')}
                {renderReferenceItem(t('created_at_ltl'), moment(consignment.created_at).format('DD MMM YYYY'))}
                {renderReferenceItem(
                    t('status'),
                    prettyStatusNames[consignment.status]
                    || snakeToPretty(consignment.status),
                )}
                {renderReferenceItem(t('pickup_attempt_count'), consignment.pickup_attempt_count)}
                {renderReferenceItem(t('attempt_count'), consignment.attempt_count)}
                {renderPrint()}
            </div>
        );
    };

    const renderTracking = () => {
        return (
            <div className={classes.trackingBox}>
                <ConsignmentTracking
                    referenceNumber={referenceNumber}
                />
            </div>
        );
    };

    // const renderTitle = (title: string) => {
    //     return (
    //         <div className={classes.trackingTitle}>
    //             {title}
    //         </div>
    //     );
    // };

    // const renderLine = () => {
    //     return <div className={classes.hr} />;
    // };

    // const renderConsignor = () => {
    //     return (
    //         <div className={classes.addresDetails}>
    //             <span className={classes.addressTitle}>
    //                 {consignment.sender_name}
    //             </span>
    //             <span className={classes.addressValue}>
    //                 {consignment.sender_address_line_1 || ''}
    //                 &nbsp;
    //                 {consignment.sender_address_line_2 || ''}
    //                 &nbsp;
    //                 {consignment.sender_city || ''}
    //                 &nbsp;
    //                 {consignment.sender_state || ''}
    //                 &nbsp;
    //                 {consignment.sender_pincode || ''}
    //                 &nbsp;
    //                 {consignment.sender_country || ''}
    //             </span>
    //         </div>
    //     );
    // };

    // const renderConsignee = () => {
    //     return (
    //         <div className={classes.addresDetails}>
    //             <span className={classes.addressTitle}>
    //                 {consignment.destination_name}
    //             </span>
    //             <span className={classes.addressValue}>
    //                 {consignment.destination_address_line_1 || ''}
    //                 &nbsp;
    //                 {consignment.destination_address_line_2 || ''}
    //                 &nbsp;
    //                 {consignment.destination_city || ''}
    //                 &nbsp;
    //                 {consignment.destination_state || ''}
    //                 &nbsp;
    //                 {consignment.destination_pincode || ''}
    //                 &nbsp;
    //                 {consignment.destination_country || ''}
    //             </span>
    //         </div>
    //     );
    // };

    // const renderConsigneeAddress = () => {
    //     return (
    //         <div className={classes.address}>
    //             {renderTitle('Consignee Details')}
    //             {renderLine()}
    //             {renderConsignee()}
    //         </div>
    //     );
    // };

    // const renderConsignorAddress = () => {
    //     return (
    //         <div className={classes.address}>
    //             {renderTitle('Consignor Details')}
    //             {renderLine()}
    //             {renderConsignor()}
    //         </div>
    //     );
    // };

    // const renderAddresses = () => {
    //     return (
    //         <div className={classes.addresses}>
    //             {renderConsigneeAddress()}
    //             {renderConsignorAddress()}
    //         </div>
    //     );
    // };

    // const renderItemType = (key?: string, value?: string) => {
    //     return (
    //         <div
    //             className={classes.itemType}
    //         >
    //             <span className={classes.itemKey}>{key}</span>
    //             <span className={classes.pairValue}>{value}</span>
    //         </div>
    //     );
    // };

    // const renderPair = (key?: string, value?: string) => {
    //     return (
    //         <div
    //             className={classes.keyValuePair}
    //         >
    //             <span className={classes.pairKey}>{key}</span>
    //             <span className={classes.pairValue}>{value}</span>
    //         </div>
    //     );
    // };

    // const renderServicePair = (key?: string, value?: string) => {
    //     return (
    //         <div
    //             className={classes.keyValuePair}
    //             style={{ width: '50%' }}
    //         >
    //             <span
    //                 className={classes.pairKey}
    //                 style={{ width: '30%' }}
    //             >
    //                 {key}
    //             </span>
    //             <span
    //                 className={classes.pairValue}
    //                 style={{ width: '60%' }}
    //             >
    //                 {value}
    //             </span>
    //         </div>
    //     );
    // };

    // const renderItems = () => {
    //     return (
    //         <div className={classes.itemsList}>
    //             <div className={classes.flexRow}>
    //                 {renderItemType('Item Type', consignment.load_type)}
    //             </div>
    //             <div className={classes.flexRow}>
    //                 {renderPair('Weight', consignment.weight)}
    //                 {!allowMultiplePieceDimensions ? renderPair('Length', consignment.length) : renderPair()}
    //                 {renderPair('No. of Pieces', consignment.num_pieces)}
    //             </div>
    //             <div className={classes.flexRow}>
    //                 {renderPair()}
    //                 {!allowMultiplePieceDimensions ? renderPair('Width', consignment.width) : renderPair()}
    //                 {renderPair('Movement Type', prettyMovementTypes[consignment.movement_type])}
    //             </div>
    //             <div className={classes.flexRow}>
    //                 {renderPair()}
    //                 {!allowMultiplePieceDimensions ? renderPair('Height', consignment.height) : renderPair()}
    //                 {renderPair('Declared Value', consignment.declared_value)}
    //             </div>
    //             <div className={classes.flexRow}>
    //                 {renderPair('Description', consignment.description)}
    //             </div>
    //         </div>
    //     );
    // };

    // const renderItemDetails = () => {
    //     return (
    //         <div className={classes.itemDetails}>
    //             {renderTitle('Item Details')}
    //             {renderLine()}
    //             {renderItems()}
    //         </div>
    //     );
    // };

    // const renderServiceDetails = () => {
    //     return (
    //         <div className={classes.itemsList}>
    //             <div className={classes.flexRow}>
    //                 {renderServicePair('Service', consignment.service_type)}
    //                 {renderServicePair('COD Amount', consignment.cod_amount)}
    //             </div>
    //             <div className={classes.flexRow}>
    //                 {renderServicePair()}
    //                 {renderServicePair('Mode', consignment.cod_collection_mode)}
    //             </div>
    //         </div>
    //     );
    // };

    // const renderService = () => {
    //     return (
    //         <div className={classes.serviceDetails}>
    //             {renderTitle('Service and Payment Details')}
    //             {renderLine()}
    //             {renderServiceDetails()}
    //         </div>
    //     );
    // };

    // const renderConsignmentDetails = () => {
    //     return (
    //         <div className={classes.consignmentDetails}>
    //             {renderItemDetails()}
    //             {renderAddresses()}
    //             {renderService()}
    //         </div>
    //     );
    // };

    const renderDetails = () => {
        if (loading) {
            return (
                null
            );
        }
        return (
            <div className={classes.detailsBox}>
                {renderTracking()}
                <ConsignmentDetailsInternational consignment={consignment} />
                {/* {
                    consignment.is_international || showNewDetailedView
                        ? <ConsignmentDetailsInternational consignment={consignment} />
                        : renderConsignmentDetails()
                } */}
            </div>
        );
    };

    return (
        <>
            {renderBack()}
            {renderReference()}
            {renderDetails()}
        </>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: consignmentDetailedStyles,
    },
};

export default GenericHoc(hocConfig)(ConsignmentDetailed);
