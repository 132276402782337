const config = {
	CSP_VIOLATION_REPORT_URI: 'http://35.85.44.168:5002/report/customer_portal',
AUTH_RSA_PUBLIC_KEY : "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8U+fRUkt2fV+HiYLPRvVZUH6QHL/PU60+EfKX3A6p9tMc/Ntg2N1B9aBXXjZA4gIFcRO5w6PpzWA7dqrzKQDyIdNtExjkaeA8KLLUm+JE5IN5P14yknCqgb6AtJB3aFWD2VipXH/U4tLaEona7AIpf4mzy6EWZdmC2c3kbb0dW5WQg1yemO+P8JpExvNpAu8fKwZWq1so4hGgR7paYCsaH+qqCy8h5wu8mSHgWldQfJtgGomEBDu5P91ouA59ri6NVeXMCSJMfDvYgW5XiBbCo5kHgtE+0cvtmTA3w58Mr4I6qlJM2teJkPEWZvDLdMlOBaH3M6cH1DR0xcHhkB8cwIDAQAB-----END PUBLIC KEY-----",
	API_DOC_FRONTEND_BASE_URL: 'https://apiplayground.shipsy.in',
			API_BASE_URL: 'https://app.shipsy.in',
			API_BASE_URL_CONFIG: {
					'ksaorgdemo': 'https://ksaapi.shipsy.io',
					'ajex': 'https://ksaapi.shipsy.io',
					'aone-portal': 'https://ksaapi.shipsy.io',
					'app2customerportal.tibbygo.com': 'https://ksaapi.shipsy.io',
					'ypserverdemo': 'https://yemenapi.shipsy.io',
					'yemenpost': 'https://yemenapi.shipsy.io',
					'uaedemo': 'https://projectxuaeapi.shipsy.io',
					'zajel': 'https://projectxuaeapi.shipsy.io',
					'merchant.zajel.com': 'https://projectxuaeapi.shipsy.io',
					'tibbygo': 'https://ksaapi.shipsy.io',
					'gcpsaudidemo': 'https://saudiapi.shipsy.io',
					'hellmann': 'https://projectxeuapi.shipsy.io',
					'shipsyazure': 'https://swedenapi.shipsy.io',
                                          'chronodiali': 'https://projectxuaeapi.shipsy.io',
			},
			BUSINESS_BOOKING_API_CONFIG: {
				'ksaorgdemo': 'https://ksaobbv2api.shipsy.io',
					'ajex': 'https://ksaobbv2api.shipsy.io',
					'aone-portal': 'https://ksaobbv2api.shipsy.io',
					'app2customerportal.tibbygo.com': 'https://ksaobbv2api.shipsy.io',
					'ypserverdemo': 'https://yemenobbv2api.shipsy.io',
					'yemenpost': 'https://yemenobbv2api.shipsy.io',
					'uaedemo': 'https://obbv2uaeapi.shipsy.io',
					'zajel': 'https://obbv2uaeapi.shipsy.io',
					'merchant.zajel.com': 'https://obbv2uaeapi.shipsy.io',
					'tibbygo': 'https://ksaobbv2api.shipsy.io',
					'gcpsaudidemo': 'https://saudiobbv2api.shipsy.io',
					'hellmann': 'https://obbv2euapi.shipsy.io',
					'shipsyazure': 'https://swedenobbv2api.shipsy.io',
                                          'chronodiali': 'https://obbv2uaeapi.shipsy.io',
			},
			filterConfig: {
					"tatamotors": {
							"customerReferenceNumberPresent": true
					}
			},
			ROUTE_OPTIMISATION_BASE_URL: '',
			BUSINESS_BOOKING_API_URL: 'https://onlinebookingmultitenantbackend.shipsy.in',
			RENDER_PERSISTENT_IFRAME_OPS: true,
			useOnlyForLocalTestingOrgId: '1',
			LOGO_URL: 'https://s3-us-west-2.amazonaws.com/shipsy-public-assets/dtdc/dpdDtdc.jpg',
			allowedTrackingEvents: ['ITD', 'DLV', 'OUTDLV', 'NONDLV', 'ARH', 'BKD'],
			IFRAME_NON_STRICT_MODE: true,
			CP_BASE_URL: 'https://{organisationId}.customerportal.shipsy.in',
			GA_MID: 'G-Z2GRVQFK3Q',
			ANALYTICS_FRONTEND_BASE_URL: 'customeranalyticsdashboard.shipsy.io',
			STADIA_MAPS_API_KEY: '74f4fc5f-7a1b-4b36-810c-b0250e964083',
	displayConfig: {
			"tatamotors": {
					customerReferenceNumberList: "Invoice Number"
			},
			"tatamotorsdemo": {
					customerReferenceNumberList: "Invoice Number"
			},
	},
FINANCE_SERVICE_BACKEND_URL: "https://financeservice.shipsy.io"
	};
	module.exports.default = config;
