import * as React from 'react';
import { useState } from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Row,
    FormInstance,
    Button,
} from 'antd';
import { getPieceLevelFieldFromMapping } from '../generic-sub-layout-renderer';
import { uniqueId } from 'lodash';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

interface UpdatedFields {
    [key: string]: any;
}

const PieceField = (props: IProps) => {
    const [pieceIndices, setPieceIndices] = useState([0]);
    const [itemIndices, setItemIndices] = useState([[0]]);

    const { t } = useTranslation();
    const {
        form,
        params,
        pieceLayout,
    } = props;

    const { numberPieces, setNumberPieces } = params;

    const addPiece = () => {
        const newPieceIndex = pieceIndices.length;

        setNumberPieces(numberPieces + 1);
        setPieceIndices([...pieceIndices, newPieceIndex]);
        form.setFieldsValue({ numberOfPieces: numberPieces + 1 });
    };

    const fields = [
        'reference_number',
        'description',
        'length',
        'width',
        'height',
        'weight',
        'weightUnit',
        'unit',
        'itemUnit',
        'quantity',
        'codAmount',
        'manufacturingCountry',
        'totalTax',
        'taxPercentage',
        'piecesItemDetails',
        'meis',
        'purchaseOrderNumber',
        'eccn',
        'pieceProductCode',
        'hsCode',
        'iossCode',
        'isKit',
        'dangerousGood',
        'isDangerousGood',
        'dangerousGoodAccessible',
        'dangerousGoodUnNumber',
        'dangerousGoodClass',
        'dangerousGoodName',
        'dangerousGoodDivision',
        'dangerousGoodPackingGroup',
        'dangerousGoodCategory',
        'dangerousGoodCategoryType',
        'dangerousGoodCategoryPackingInstruction',
        'valid_purchaseOrderNumber',
        'declaredValue',
    ];

    const removePiece = (index: number) => {
        const updatedFields: UpdatedFields = {};

        for (let i = 0; i < numberPieces; i += 1) {
            if (i !== index) {
                fields?.forEach((field) => {
                    updatedFields[`${field}_${i < index ? i : i - 1}`] = form.getFieldValue(`${field}_${i}`);
                });
            }
            if (i === numberPieces - 1) {
                fields?.forEach((field) => {
                    updatedFields[`${field}_${i}`] = undefined;
                });
            }
        }

        const newPieceIndices = [...pieceIndices];
        newPieceIndices.pop();
        setPieceIndices(newPieceIndices);
        setNumberPieces(numberPieces - 1);
        form.setFieldsValue(updatedFields);
        form.setFieldsValue({ numberOfPieces: numberPieces - 1 });
    };

    const renderAddMoreButton = () => {
        if (true) {
            return (
                <Button
                    type="primary"
                    onClick={addPiece}
                    style={{ margin: '10px 0' }}
                >
                    {t('add_more_text')}
                </Button>
            );
        }
        return <></>;
    };


    const renderRemovePiece = (index: number) => {
        return (
            <Button danger type="link" onClick={() => removePiece(index)}>{t('remove')}</Button>
        );
    };

    const pieceComponent = (
        <>
            {pieceIndices?.map((pieceIndex) => {
                const pieceForm = pieceLayout?.map(
                    (fieldKey: any) => getPieceLevelFieldFromMapping(fieldKey, form, {
                        ...params,
                        itemIndices,
                        setItemIndices,
                        id: pieceIndex,
                        key: `${fieldKey}_${pieceIndex}`,
                        pieceIndex,
                    }),
                );
                return (
                    <div
                        key={pieceIndex}
                        style={{
                            marginBottom: '5px',
                            padding: '10px',
                            backgroundColor: '#fafafa',
                            borderRadius: '5px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <strong
                                style={{ fontSize: 15, paddingTop: '10px', paddingBottom: '10px' }}
                            >
                                {'Piece '}
                                {pieceIndex + 1}
                            </strong>
                            {pieceIndices?.length !== 1 && renderRemovePiece(pieceIndex)}
                        </div>
                        <Row gutter={10}>
                            {pieceForm}
                        </Row>
                    </div>
                );
            })}
        </>
    );

    return (
        <Col span={24}>
            {pieceLayout?.length > 0 && (
                <div
                    key={uniqueId() + (+new Date())}
                >
                    {pieceComponent}
                    {renderAddMoreButton()}
                </div>
            )}
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const pieceLayout = formSubLayout?.piece || [];
    return {
        pieceLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PieceField);
