import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import Paginator from '../common/paginator';
import moment from 'moment';
import { NextOrPrev, Pagination } from '../../library/Types';
import { Statement, WalletPages } from './wallet.types';
import { RouteChildrenProps } from 'react-router';
import { Routes } from '../../library/constants';
import GenericHoc from '../common/generic-hoc';
import { walletColumns, WalletPageOptions } from './wallet.constants';
import {
    getBlockedLedgerRoute,
    getWalletLedgerRoute,
    getBlockedAmountRoute,
} from '../../routing/routing-helper';
import { walletStyles } from './wallet.styles';
import {
    fetchCustomerWalletData,
    fetchWalletLedger,
    fetchWalletBlockedAmount,
    fetchRechargeTransactions,
    getRedirectionURL,
    fetchPaymentStatus,
} from '../../network/wallet.api';
import WalletIcon from '../../assets/wallet-balance.png';
import {
    Button, DatePicker, Drawer, Input, message, Spin, Table,
} from 'antd';
import WalletFaq from './wallet-faq';
import { ReduxStore } from 'reducers/redux.types';
import {
    Master,
} from '../../types/master-data-types';
import { ReloadOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import reloadSvg from './icons/reload.svg';

interface WalletProps
    extends StylesProps<ReturnType<typeof walletStyles>>, RouteChildrenProps {
    uiTheme: ThemeType;
    master: Master;
}

const {
    useState,
    useEffect,
} = React;

const WalletPage = (props: WalletProps) => {
    const {
        classes,
        history,
        master,
        uiTheme,
    } = props;

    const [rechargeVisible, setRechargeVisible] = React.useState(false);
    const [faqVisible, setFaqVisible] = React.useState(false);
    const customerProperties = master?.properties;
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    const paymentModesList = master?.customer_wallet_config?.customerWalletPaymentMethods || [];
    const timezone = master?.config?.timezone;
    let reference_id:string;
    if (Array.isArray(paymentModesList) && paymentModesList.length > 0) {
        reference_id = paymentModesList[0]?.reference_id;
    }

    let showWallet = true;
    if (customerProperties && enable_customer_config) {
        showWallet = customerProperties?.Wallet;
    }

    const getInitialPage = (): WalletPages => {
        if (!showWallet) {
            return 'BLOCKED_AMOUNT';
        }
        switch (history.location.pathname) {
            case `/${Routes.WALLET_LEDGER}`: return 'WALLET_LEDGER';
            case `/${Routes.RECHARGE_TRANSACTIONS}`: return 'RECHARGE_TRANSACTIONS';
            case `/${Routes.BLOCKED_AMOUNT_LEDGER}`:
            default: return 'BLOCKED_AMOUNT';
        }
    };

    const [pageSelected, setPageSelected] = useState<WalletPages>(getInitialPage());
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [statement, setStatement] = React.useState<Statement[]>([]);
    const [walletLedgerData, setWalletLedgerData] = React.useState<any>({});
    const [blockedLedger, setblockedLedger] = React.useState<Statement[]>([]);
    const [rechargeTransactions, setRechargeTransactions] = React.useState<Statement[]>([]);
    const [customerWalletData, setCustomerWalletData] = React.useState<any>({});
    const [walletLedgerSection, setWalletLedgerSection] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [amount, setAmount] = useState(0);
    const [isReloadDisabled, setIsReloadDisabled] = useState(false);
    const [filters, setFilters] = React.useState({
        fromDate: moment().subtract(7, 'days'),
        toDate: moment(),
    });
    const [pagination, setPagination] = React.useState<Pagination>({
        currentPageNumber: 1,
        resultPerPage: 50,
        nextOrPrev: 'first',
        isNextPresent: false,
    });

    const getParams = () => {
        return {
            fromDate: moment(filters.fromDate).format('YYYY-MM-DD'),
            toDate: moment(filters.toDate).format('YYYY-MM-DD'),
            limit: pagination.resultPerPage,
            pageNumber: pagination.currentPageNumber - 1,
            timezone,
        };
    };

    const loadWalletLedger = async () => {
        setLoading(true);
        const params = getParams();
        const response = await fetchWalletLedger(
            params,
        );
        setStatement(response?.data?.transactionLogs || []);
        const isNext = response?.data?.isNextPresent || false;
        const newPagination = {
            ...pagination,
            isNextPresent: isNext,
        };
        setPagination(newPagination);
        setWalletLedgerData(response?.data || {});
        if (!response?.isSuccess) {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };
    const loadBlockedAmount = async () => {
        const params = getParams();
        setLoading(true);
        const response = await fetchWalletBlockedAmount(
            params,
        );

        setblockedLedger(response?.data?.transactionLogs || []);
        const isNext = response?.data?.isNextPresent || false;
        const newPagination = {
            ...pagination,
            isNextPresent: isNext,
        };
        setPagination(newPagination);
        if (!response?.isSuccess) {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    const loadRechargeTransactions = async () => {
        const params = getParams();
        setLoading(true);
        const response = await fetchRechargeTransactions(
            params,
        );

        setRechargeTransactions(response?.data?.transactionLogs || []);
        const isNext = response?.data?.isNextPresent || false;
        const newPagination = {
            ...pagination,
            isNextPresent: isNext,
        };
        setPagination(newPagination);
        if (!response?.isSuccess) {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    const loadStatement = () => {
        if (pageSelected === 'WALLET_LEDGER') {
            setWalletLedgerSection(true);
            loadWalletLedger();
        } else if (pageSelected === 'BLOCKED_AMOUNT') {
            setWalletLedgerSection(false);
            loadBlockedAmount();
        } else {
            setWalletLedgerSection(false);
            loadRechargeTransactions();
        }
    };

    const loadCustomerWalletData = async () => {
        const response = await fetchCustomerWalletData();
        if (!response?.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setCustomerWalletData(response?.data || {});
        }
    };

    useEffect(() => {
        loadCustomerWalletData();
        loadStatement();
    }, [pageSelected, filters, pagination.currentPageNumber]);

    const renderText = (text: string) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const checkPaymentStatus = async (record: any) => {
        if (record?.status === 'PENDING') {
            setLoading(true);
            const response = await fetchPaymentStatus({ transactionId: record?.transaction_id, referenceId: reference_id });
            if (response?.isSuccess) {
                const currentPaymentStatus = get(response, 'data.paymentStatusResult.data.payment_status');
                if (currentPaymentStatus === 'SUCCESS') {
                    message.success(`Payment status: ${currentPaymentStatus}`);
                } else {
                    message.warning(`Payment status: ${currentPaymentStatus}`);
                }
            } else {
                message.error('Failed to fetch payment status');
            }
            setLoading(false);
        }
    };

    const renderCheckPaymentStatus = (status: string) => {
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <span className={classes.cellValue}>
                        {status}
                    </span>
                    <div className={classes.reloadSvgButton}>
                        <img src={reloadSvg} alt="Not Found" style={{ height: '18px', width: '18px' }} />
                    </div>
                </div>
            </div>
        );
    };

    const renderColumn = (text: string, column: any) => {
        if (!text || text === null) {
            return <div className={classes.cellNa}>-</div>;
        }

        if (column === 'date' || column === 'created_at') {
            const dateString = moment(text).format('DD MMM, YYYY | HH:mm:ss');
            return renderText(dateString);
        }

        if (column === 'credit_debit_flag') {
            return renderText(text?.toUpperCase());
        }

        if (column === 'status') {
            return text === 'SUCCESS' ? renderText(text) : renderCheckPaymentStatus(text);
        }

        return renderText(text);
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'action_type':
            case 'actions': return 60;
            case 'status':
            case 'reference_number':
            case 'destination_name':
            case 'creation_source':
            case 'customer_reference_number': return 150;
            case 'movement_type':
            default: return 120;
        }
    };

    const getColumns = (): any[] => {
        const columnLables: Record<string, string> = walletColumns[pageSelected];

        const columns: any = Object.keys(columnLables).map((column: string) => {
            const columnConfig = {
                key: column,
                title: columnLables[column],
                dataIndex: column,
                width: getWidth(column),
                ellipsis: true,
                fixed: column === 'actions' ? 'right' : undefined,
                render: (text: string) => renderColumn(text, column),
                ...(column === 'status' && {
                    onCell: (record: any) => ({
                        onClick: () => checkPaymentStatus(record),
                    }),
                }),
            };

            return columnConfig;
        });
        return columns;
    };

    const getDataSource = () => {
        switch (pageSelected) {
            case 'WALLET_LEDGER': return statement;
            case 'RECHARGE_TRANSACTIONS': return rechargeTransactions;
            case 'BLOCKED_AMOUNT':
            default: return blockedLedger;
        }
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading}
                className={classes.table}
                rowKey={(row) => row.id}
                columns={getColumns()}
                locale={{
                    emptyText: <div className={classes.cellNa}>—</div>,
                }}
                dataSource={getDataSource() || []}
            />
        );
    };

    const handlePageChange = (page: WalletPages) => {
        if (page === pageSelected) {
            return;
        }
        let nextPage = getWalletLedgerRoute();
        switch (page) {
            case 'WALLET_LEDGER': nextPage = getWalletLedgerRoute(); break;
            case 'RECHARGE_TRANSACTIONS': nextPage = getBlockedLedgerRoute(); break;
            case 'BLOCKED_AMOUNT':
            default: nextPage = getBlockedAmountRoute(); break;
        }
        setStatement([]);
        setPageSelected(page);
        history.push(nextPage);
    };

    const renderPageOption = (page: WalletPages) => {
        return (
            <div
                key={page}
                onClick={() => handlePageChange(page)}
                className={pageSelected === page ? classes.selectedPage : classes.pageName}
            >
                {WalletPageOptions[page]}
            </div>
        );
    };

    const renderPageFilter = () => {
        return (
            <div className={classes.pageFilter}>
                {Object.keys(WalletPageOptions).map((page) => renderPageOption(page as WalletPages))}
            </div>
        );
    };

    const renderDateField = () => {
        return (
            <div className={classes.filter}>
                <DatePicker.RangePicker
                    value={[moment(filters.fromDate), moment(filters.toDate)]}
                    placeholder={['Select Start Date', 'Select End Date']}
                    onChange={(value) => {
                        if (!(value && value[0] && value[1])) return;
                        const newFilters = {
                            ...filters,
                        };
                        newFilters.fromDate = moment(value[0]);
                        newFilters.toDate = moment(value[1]);
                        setFilters(newFilters);
                    }}
                />
            </div>
        );
    };

    const renderOpeningClosingBalance = () => {
        return (
            <div className={classes.openingClosingBalanceSecton}>
                <div className={classes.balanceSection}>
                    <div style={{ fontWeight: 'bold' }}>
                        {walletLedgerData?.openingBalance}
                    </div>
                    <div style={{ fontSize: '11px' }}>
                        Opening Balance
                    </div>
                </div>
                <div className={classes.balanceSection}>
                    <div style={{ fontWeight: 'bold' }}>
                        {walletLedgerData?.closingBalance}
                    </div>
                    <div style={{ fontSize: '11px' }}>
                        Closing Balance
                    </div>
                </div>
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderDateField()}
                {walletLedgerSection && renderOpeningClosingBalance()}
            </div>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination || {};
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setPagination(newPagination);
        setToggle(!toggle);
    };

    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination?.currentPageNumber || 1}
                isNextPresent={pagination?.isNextPresent}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
                onFirstClick={() => handlePagination('first')}
            />
        );
    };

    const reloadPage = () => {
        if (!isReloadDisabled) {
            setIsReloadDisabled(true);
            loadStatement();
            setTimeout(() => {
                setIsReloadDisabled(false);
            }, 10000);
        }
    };

    const renderReload = () => {
        return (
            <ReloadOutlined
                onClick={() => reloadPage()}
                disabled={isReloadDisabled}
                className={isReloadDisabled ? classes.reloadDisabled : classes.reloadIcon}
            />
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderReload()}
                {renderPaginator()}
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderIcon = () => {
        return (
            <img
                alt="bulk-pickup"
                src={WalletIcon}
                className={classes.img}
            />
        );
    };

    const renderCurrentBalance = () => {
        const availableBalance = Number(customerWalletData.availableBalance || 0);
        const blockedBalance = Number(customerWalletData.blockedBalance || 0);
        return (
            <div className={classes.balance}>
                <div>
                    {Intl.NumberFormat('en-IN').format(availableBalance + blockedBalance)}
                </div>
                <span className={classes.balanceText}>
                    Total Balance
                </span>
            </div>
        );
    };

    const renderSign = (sign: any) => {
        return (
            <span className={classes.signText}>
                {sign}
            </span>
        );
    };

    const renderMinumumBalance = () => {
        const minimumBalance = Number(customerWalletData?.minimumBalance || 0);
        return (
            <div className={classes.balance}>
                <div>
                    {Intl.NumberFormat('en-IN').format(minimumBalance)}
                </div>
                <span className={classes.balanceText}>
                    Minimum Balance
                </span>
            </div>
        );
    };

    const renderAvailableBalance = () => {
        return (
            <div className={classes.balance}>
                <div>
                    {Intl.NumberFormat('en-IN').format(Number(customerWalletData?.availableBalance || 0))}
                </div>
                <span className={classes.balanceText}>
                    Available Balance
                </span>
            </div>
        );
    };

    const renderBlockedBalance = () => {
        return (
            <div className={classes.balance}>
                <div>
                    {Intl.NumberFormat('en-IN').format(Number(customerWalletData.blockedBalance || 0))}
                </div>
                <span className={classes.balanceText}>
                    Blocked Amount
                </span>
            </div>
        );
    };

    const renderBalances = () => {
        return (
            <div className={classes.balanceDiv}>
                {renderIcon()}
                {renderCurrentBalance()}
                {renderSign('=')}
                {renderBlockedBalance()}
                {renderSign('+')}
                {renderAvailableBalance()}
                {renderSign('|')}
                {renderMinumumBalance()}
            </div>
        );
    };

    const renderRecharge = () => {
        return (
            <Button
                onClick={() => setRechargeVisible(true)}
                type="primary"
                style={{
                    height: 32,
                    fontSize: 12,
                    backgroundColor: uiTheme.primaryColor,
                    border: `1px solid ${uiTheme.primaryColor}`,
                    color: '#FFFFFF',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    letterSpacing: 0,
                    padding: '0px 24px',
                    marginLeft: 12,
                    borderRadius: 4,
                }}
            >
                Recharge
            </Button>
        );
    };

    const renderFAQsButton = () => {
        return (
            <Button
                onClick={() => setFaqVisible(true)}
                type="primary"
                style={{
                    height: 32,
                    fontSize: 12,
                    backgroundColor: uiTheme.primaryColor,
                    border: `1px solid ${uiTheme.primaryColor}`,
                    color: '#FFFFFF',
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    letterSpacing: 0,
                    padding: '0px 24px',
                    borderRadius: 4,
                    marginLeft: 5,
                }}
            >
                Check FAQs
            </Button>
        );
    };

    const renderFAQsText = () => {
        return (
            <span className={classes.faqText}>
                Have doubts regarding your virtual wallet?
            </span>
        );
    };

    const renderFAQs = () => {
        return (
            <div className={classes.faqBox}>
                {renderFAQsText()}
                {renderFAQsButton()}
            </div>
        );
    };

    const renderBalance = () => {
        return (
            <div className={classes.cashNCarry}>
                {renderBalances()}
                <div className={classes.recharge}>
                    {renderFAQs()}
                    {renderRecharge()}
                </div>
            </div>
        );
    };

    const renderWalletPage = () => {
        return (
            <>
                {renderTable()}
            </>
        );
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        if (!Number.isNaN(Number(inputValue))) {
            setAmount(Number(inputValue));
        } else {
            message.error('Please enter a numeric value.');
        }
    };

    const handleButtonClick = (value: number) => {
        setAmount((prevAmount) => prevAmount + value);
    };

    const closeRechargeModal = () => {
        setRechargeVisible(false);
        setAmount(0);
    };

    const renderContent = () => {
        if (loading) {
            return null;
        }

        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {renderIcon()}
                    <div style={{ marginLeft: '16px' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            {Number(customerWalletData.availableBalance || 0)}
                        </div>
                        <div style={{ fontSize: '13px' }}>Available Balance</div>
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>Enter Amount</div>
                <Input style={{ width: '42%' }} value={amount} onChange={handleInputChange} />
                <div className={classes.paymentButtonsCollection}>
                    <Button className={classes.paymentAddition} onClick={() => handleButtonClick(500)}>+ 500</Button>
                    <Button className={classes.paymentAddition} onClick={() => handleButtonClick(1000)}>+ 1000</Button>
                    <Button className={classes.paymentAddition} onClick={() => handleButtonClick(2000)}>+ 2000</Button>
                    <Button className={classes.paymentAddition} onClick={() => handleButtonClick(5000)}>+ 5000</Button>
                    <Button className={classes.paymentAddition} onClick={() => handleButtonClick(10000)}>+ 10000</Button>
                </div>
            </div>
        );
    };

    const redirectToPayphi = async (rechargeAmount: number, referenceId: string) => {
        setLoading(true);
        const response = await getRedirectionURL({ amount: rechargeAmount, referenceId });
        if (response?.isSuccess) {
            // can get transaction id from response as well
            const { data } = response;
            const url = data?.qrData?.data?.redirectURI;
            window.open(url, '_blank');
        } else {
            message.error('Failed to recharge virtual wallet');
        }
        setLoading(false);
    };

    const renderHeader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontWeight: '650' }}>
                    Recharge Virtual Wallet
                </div>
                <Button type="primary" onClick={() => redirectToPayphi(amount, reference_id)}>
                    Recharge
                </Button>
            </div>
        );
    };

    const renderRechargeModal = () => {
        if (!rechargeVisible) {
            return null;
        }
        return (
            <Drawer
                title={renderHeader()}
                visible={rechargeVisible}
                onClose={() => closeRechargeModal()}
                footer="Click on the Recharge button to recharge your Virtual Wallet"
                width="30%"
            >
                {loading && (
                    <Spin
                        style={{
                            marginLeft: '45%',
                            marginTop: 100,
                            marginBottom: 100,
                        }}
                    />
                )}
                {renderContent()}
            </Drawer>
        );
    };

    const renderFAQModal = () => {
        if (!faqVisible) {
            return null;
        }
        return (
            <WalletFaq
                onCancel={() => setFaqVisible(false)}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderBalance()}
            {renderPageFilter()}
            {renderExtraFilters()}
            {renderWalletPage()}
            {renderRechargeModal()}
            {renderFAQModal()}
        </div>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: walletStyles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(WalletPage);
