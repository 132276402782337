import * as React from 'react';
import PrimaryIcon from '../single-consignment-icons/primary';
import ContentsIcon from '../single-consignment-icons/content';
import TextArea from 'antd/lib/input/TextArea';
import Loader from '../../common/Loader';
import {
    getChildList,
    getContentList,
    getCurrencyCodes,
    getPudoHubs,
    fetchItemUnits,
} from '../../../network/consignments.api';
import {
    Category,
    Commodity,
    LabelValue,
    PudoHubs,
} from '../../../library/Types';
import { StylesProps } from '../../../theme/jss-types';
import { FormField, InputTypes } from '../create-consignment.types';
import { formFields, itemFormFields } from '../create-modal.constants';
import { stepOneStyles } from '../single-consignment.styles';
import {
    Checkbox,
    Form,
    FormInstance,
    Input,
    Radio,
    Select,
    Cascader,
    Button,
    InputNumber,
    DatePicker,
    Space,
    Row,
    Col,
} from 'antd';
// import { formRules } from '../../../library/constants';
import { HocOptions } from '../../common/generic-hoc.types';
import GenericHoc from '../../common/generic-hoc';
import {
    get,
    last,
    omit,
    uniqueId,
} from 'lodash';
import Helper from 'library/Helper';
import ItemDetails from './item-details';
import { formRules } from 'library/constants';
import { getCountries } from 'network/common.api';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

const {
    Unit,
    Weight,
    Length,
    Width,
    Height,
    ItemType,
    WeightUnit,
    NumPieces,
    ContentTypes,
    Description,
    MovementType,
    RetailTransaction,
    DeclaredValue,
    DeclaredValueCurrency,
    RiskSurcharge,
    CustomerReference,
    CashonPickup,
    ConsignmentCategory,
    VehicleCategory,
    noOfItems,
    ConsignmentReference,
    SubAccount,
    DeliveryType,
    PudoStores,
    // BoxNumber,
    Vol_weight,
    Cust_Seller_code,
    shipment_purpose,
    shipment_Terms,
    DeliveryNote,
    EORI_No,
    // IORI_No,
    Against_Bond_LUT,
    ECom_Shipment,
    MEIS,
    Is_Battery,
    Currency,
    Amount,
    ContentNameForOthers,
    EwbDate,
    EwbNumber,
    ReferenceNumber,
} = formFields;

interface StepOneProps extends StylesProps<ReturnType<typeof stepOneStyles>> {
    config: any;
    formData: any;
    form: FormInstance;
    showDeliveryType: boolean;
    setFormData: any;
    fieldsToShowInternational: Record<any, boolean>;
    source: string;
    isFTL: boolean;
    loadTypeOptions: Array<{key: string; label: string; value: string;}>;
}
interface PieceDetails {
    key: any;
    height?: any;
    length?: any;
    numberOfPieces?: any;
    unit?: any;
    weight?: any;
    weightUnit?: any;
    dimensionsUnit?: any;
    width?: any;
    declaredValue?: any;
    description?: any;
    items?: Record<any, any>;
    codAmount?: any;
    reference_number?: any;
}

interface PieceTotals {
    totalWeight: number;
    totalVolume: number;
}

interface PieceUnitVolume {
    volume: number;
}

const StepOne = (props: StepOneProps) => {
    const {
        config,
        classes,
        formData,
        form,
        showDeliveryType,
        setFormData,
        fieldsToShowInternational,
        source,
        isFTL,
        loadTypeOptions,
    } = props;
    const { t, i18n } = useTranslation();
    const [itemType, setItemType] = React.useState<string | null>(null);
    const [retailTransactionType, setRetailTransactionType] = React.useState<string | null>(null);
    const [contentList, setContentList] = React.useState<Commodity[]>([]); // old
    const [currencyList, setCurrencyList] = React.useState<any[]>([]);
    const [countryList, setCountryList] = React.useState<LabelValue[]>([]);
    const [categoryList, setCategoryList] = React.useState<Category[]>([]); // new
    const [loading, setLoading] = React.useState<boolean>(true);
    const [childClients, setChildClients] = React.useState<any>([]);
    const [pieces, setPieces] = React.useState<Record<any, PieceDetails>>({});
    const [totalWeight, setTotalWeight] = React.useState<number>(0);
    const [totalVolume, setTotalVolume] = React.useState<number>(0);
    const [itemTotalWeightAndVolume, setItemTotalWeightAndVolume] = React.useState<Record<string, PieceTotals>>({});
    const [itemVolume, setItemVolume] = React.useState<Record<string, PieceUnitVolume>>({});
    const [totalWeightVolumeUnit, setTotalWeightVolumeUnit] = React.useState<Record<string, string>>(
        { weight: 'kg', volume: 'm' },
    );
    const [itemUnits, setItemUnits] = React.useState<any[]>([]);
    const [deliveryType, setDeliveryType] = React.useState<string>('HOME_DELIVERY');
    // using selectedItem, selectedPieceKey to identify details in item-details
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const [selectedPieceKey, setSelectedPieceKey] = React.useState<any>(null);
    const [showOtherName, setShowOtherName] = React.useState<boolean>(formData?.showOtherName);
    const [consignmentCategory, setConsignmentCategory] = React.useState(formData.consignmentCategory);
    const use_category = config.config?.use_category_in_commodity_data && consignmentCategory === 'domestic';
    const separateChildCN = config.parts_to_show?.separate_child_cns;
    const [pudoHubs, setPudoHubs] = React.useState<PudoHubs[]>([]);
    const [addMoreBoxes, setAddMoreBoxes] = React.useState(1);
    const [startAddMoreBoxes, setStartAddMoreBoxes] = React.useState(0);
    const allowedUnits = config?.config?.allowed_units_in_pickup;
    const itemPartialDelivery = config?.config?.item_partial_delivery;
    const consignmentConfig = config?.config?.customer_portal_consignment_config;
    const customerPortalConfig = config?.config?.customer_portal_config;
    const mandatoryPieceDetails = customerPortalConfig?.mandatory_piece_details || {};
    const customFields: Record<any, any> = {};
    const showCustomFields = consignmentConfig?.show_custom_fields;
    const partsToShowSingleConsignment = consignmentConfig?.parts_to_show_single_consignment;
    const showItemType = partsToShowSingleConsignment?.itemType !== false;
    const showTotalWeightAndVolume = partsToShowSingleConsignment && partsToShowSingleConsignment?.totalWeightAndVolume;
    const autoCalculateDetails = customerPortalConfig?.auto_calculate_single_cn_fields_international;
    const showDeclearedPriceInDocumentInt = customerPortalConfig?.show_decleared_price_in_document_int;
    const mandatoryFieldNonDocIntl = customerPortalConfig?.mandatory_field_non_document_international || {};
    const mandatoryFieldShipmentCommercial = customerPortalConfig?.mandatory_shipment_commercial || {};
    const showItemTotalWeightAndVolume = partsToShowSingleConsignment
                                        && partsToShowSingleConsignment?.itemTotalWeightAndVolume;
    const showDeclaredCurrencyField = config?.parts_to_show?.show_declared_currency_field;
    const declaredCurrenciesList = config?.parts_to_show?.declared_currency_list || [];
    const showEwayBill = config?.config?.customer_portal_consignment_config?.show_eway_bill_details || false;
    const riskSurchargeValue = Form.useWatch(RiskSurcharge.key, form);
    const declaredAmount = Form.useWatch(DeclaredValue.key, form);
    const [riskOptions, setRiskOptions] = React.useState<any[]>([
        { label: 'Owner Risk 0.2 % or Rs 25 whichever is higher', value: 'owner' },
        { label: 'Carrier Risk 2 % or Rs 25 whichever is higher', value: 'carrier' },
    ]);
    const [disableRiskSurcharge, setDisableRiskSurcharge] = React.useState<boolean>(false);
    const setChangeData = (value: any) => {
        const checkDeclaredValueIsInt = (value && !Number.isNaN(value));
        const declaredValue = checkDeclaredValueIsInt ? Number(value) : 0;
        if (declaredValue > 50000 && showEwayBill && formData.consignmentCategory !== 'international') {
            setFormData({
                ...formData,
                ewayBill: {
                    type: 'ewaybill_number',
                },
            });
        }
    };
    const hideConsignmentCategory = customerPortalConfig?.hide_consignment_category && !isFTL;
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const loadPudoHubs = async () => {
        setLoading(true);
        const response = await getPudoHubs();
        setPudoHubs(response?.data || []);
        setLoading(false);
    };

    const addPiece = () => {
        let currentPieces: Record<any, PieceDetails> = {};
        const id = uniqueId('prefixPieceId');
        if (pieces)currentPieces = { ...pieces };
        setPieces({
            ...currentPieces,
            [id]: {
                key: id,
                weightUnit: 'kg',
                dimensionsUnit: 'cm',
            },
        });
    };

    const removePiece = (key: any) => {
        setPieces(omit(pieces, key));
    };
    const removeItem = (pieceKey: any, itemKey: any) => {
        setPieces({
            ...pieces,
            [pieceKey]: {
                ...pieces[pieceKey],
                items: {
                    ...omit(pieces[pieceKey].items, itemKey),
                },
            },
        });
    };

    const handleCodAmountCalculation = () => {
        if (itemPartialDelivery) {
            const data = form.getFieldsValue();
            const pieceData = data.pieces || {};
            const pieceCodAmountSum = Object.keys(pieceData).reduce(
                (acc: number, itr: any) => acc + Number(pieceData[itr]?.codAmount || 0),
                0,
            );
            setFormData({
                ...formData,
                [Amount.key]: pieceCodAmountSum,
            });
        }
    };

    const convertCentimeterVolumeWithUnit = (volume: number, targetUnit: string) => {
        const centimeterDivider: Record<string, number> = {
            cm: 1,
            inch: 2.54,
            ft: 30.48,
            mtr: 100,
        };

        const volumeDivider: number = centimeterDivider[targetUnit] ** 3;

        const convertedVolume = volume / volumeDivider;

        return convertedVolume;
    };

    React.useEffect(() => {
        let weight = 0;
        let volume = 0;
        const data = form.getFieldsValue();
        let itemTotalWeightAndVolumeToSet = {
            ...itemTotalWeightAndVolume,
        };
        let pieceVolume = {
            ...itemVolume,
        };
        Object.keys(data.pieces || {}).forEach((key) => {
            const piece: PieceDetails = data.pieces[key];
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            // calculateTotalAndGrossWeightVolume(piece);
            const weightWithUnit = Number(piece.weight || 0) * Number(piece.numberOfPieces || 1);

            const lengthWithUnit = Helper.converttoCentimeter(Number(piece.length || 0), piece.dimensionsUnit);
            const WidthWithUnit = Helper.converttoCentimeter(Number(piece.width || 0), piece.dimensionsUnit);
            const heightWithUnit = Helper.converttoCentimeter(Number(piece.height || 0), piece.dimensionsUnit);

            const volumeWithUnit = Number(piece.numberOfPieces || 1)
            * lengthWithUnit * WidthWithUnit * heightWithUnit;
            const convertedVolume = convertCentimeterVolumeWithUnit(volumeWithUnit, 'mtr');

            itemTotalWeightAndVolumeToSet = {
                ...itemTotalWeightAndVolumeToSet,
                [key]: {
                    totalWeight: weightWithUnit,
                    totalVolume: convertedVolume,
                },
            };
            pieceVolume = {
                ...pieceVolume,
                [key]: {
                    volume: convertedVolume,
                },
            };

            weight += Number(Helper.converttoKilogram(Number(weightWithUnit), piece.weightUnit));
            volume += volumeWithUnit;
        });
        setTotalWeight(weight);
        setFormData({
            ...formData,
            pieces,
        });
        setTotalVolume(volume);
        setItemTotalWeightAndVolume(itemTotalWeightAndVolumeToSet);
        setItemVolume(pieceVolume);
    }, [pieces, totalWeightVolumeUnit]);

    // React.useEffect(() => {
    //     if (itemType === 'non-document') {
    //         form.validateFields(['Freight_Cost']);
    //     }
    // }, [itemType]);

    React.useEffect(() => {
        if (startAddMoreBoxes > 0) {
            addPiece();
        }
    }, [startAddMoreBoxes]);

    React.useEffect(() => {
        if (!riskSurchargeValue && form.getFieldValue('riskSurchargeType')) {
            form.setFieldsValue({
                riskSurchargeType: undefined,
            });
        }
    }, [riskSurchargeValue]);

    React.useEffect(() => {
        if (!config?.Customer?.is_cpdp || consignmentCategory === 'international') {
            return;
        }
        if (!config?.config?.customer_portal_config?.show_risk_surcharge_type) return;
        if (declaredAmount > 200000) {
            setRiskOptions([
                { label: 'Owner Risk 0.1 %', value: 'owner' },
            ]);
            if (form.getFieldValue('riskSurchargeType') !== 'owner') {
                form.setFieldsValue({
                    riskSurchargeType: 'owner',
                });
            }
            if (disableRiskSurcharge === false) {
                setDisableRiskSurcharge(true);
                form.setFieldsValue({
                    [RiskSurcharge.key]: true,
                });
            }
        } else if (declaredAmount > 100000) {
            setRiskOptions([
                { label: 'Owner Risk 0.1 %', value: 'owner' },
                { label: 'Carrier Risk 1 %', value: 'carrier' },
            ]);
            if (disableRiskSurcharge === false) {
                setDisableRiskSurcharge(true);
                form.setFieldsValue({
                    riskSurchargeType: 'owner',
                    [RiskSurcharge.key]: true,
                });
            }
        } else if (declaredAmount > 50000) {
            setRiskOptions([
                { label: 'Owner Risk 0.1 %', value: 'owner' },
                { label: 'Carrier Risk 2 %', value: 'carrier' },
            ]);
            if (disableRiskSurcharge === false) {
                if (!(form?.getFieldValue('riskSurchargeType')
                    && form?.getFieldValue('riskSurchargeType')?.length)) {
                    form.setFieldsValue({
                        riskSurchargeType: 'owner',
                        [RiskSurcharge.key]: true,
                    });
                }
                setDisableRiskSurcharge(true);
            }
        } else {
            if (disableRiskSurcharge === true) {
                setDisableRiskSurcharge(false);
            }
            setRiskOptions([
                { label: 'Owner Risk 0.2 % or Rs 25 whichever is higher', value: 'owner' },
                { label: 'Carrier Risk 2 % or Rs 25 whichever is higher', value: 'carrier' },
            ]);
        }
    }, [declaredAmount]);

    React.useEffect(() => {
        if (startAddMoreBoxes > 0) {
            setStartAddMoreBoxes(startAddMoreBoxes - 1);
        }
        if (consignmentCategory === 'international') {
            form.setFieldsValue({
                [NumPieces.key]: Object.keys(pieces).length,
            });
        }
    }, [pieces]);

    const calculateTotalWeightVolumeCod = () => {
        if (showTotalWeightAndVolume) {
            let weight = 0;
            let volume = 0;
            const data = form.getFieldsValue();
            Object.keys(data.pieces || {}).forEach((key) => {
                const piece: PieceDetails = data.pieces[key];
                const weightWithUnit = Number(piece.weight || 0) * Number(piece.numberOfPieces || 1);

                const lengthWithUnit = Helper.converttoCentimeter(Number(piece.length || 0), piece.dimensionsUnit);
                const WidthWithUnit = Helper.converttoCentimeter(Number(piece.width || 0), piece.dimensionsUnit);
                const heightWithUnit = Helper.converttoCentimeter(Number(piece.height || 0), piece.dimensionsUnit);

                const volumeWithUnit = Number(piece.numberOfPieces || 1)
                * lengthWithUnit * WidthWithUnit * heightWithUnit;

                weight += Number(Helper.converttoKilogram(Number(weightWithUnit), piece.weightUnit));
                volume += volumeWithUnit;
            });
            setTotalWeight(weight);
            setTotalVolume(volume);
        }
        if (consignmentCategory === 'international' && autoCalculateDetails) {
            let weight = 0;
            let declaredValue = 0;
            const data = form.getFieldsValue();
            const toUpdate: Record<any, any> = { pieces: {} };
            Object.keys(data.pieces || {}).forEach((key) => {
                const piece: PieceDetails = data.pieces[key];
                declaredValue += Number(piece.declaredValue || 0);
                weight += Number(Helper.converttoKilogram(Number(piece.weight || 0), piece.weightUnit));
                const volWeight = Helper.converttoCentimeter(Number(piece.length || 0), piece.dimensionsUnit)
                * Helper.converttoCentimeter(Number(piece.width || 0), piece.dimensionsUnit)
                * Helper.converttoCentimeter(Number(piece.height || 0), piece.dimensionsUnit);
                toUpdate.pieces[key] = {};
                toUpdate.pieces[key][Vol_weight.key] = volWeight / 5000;
            });
            form.setFieldsValue({
                ...toUpdate,
                [DeclaredValue.key]: declaredValue,
                [Weight.key]: weight,
            });
        }

        handleCodAmountCalculation();
    };

    React.useEffect(() => {
        if (startAddMoreBoxes > 0) {
            setStartAddMoreBoxes(startAddMoreBoxes - 1);
        }
        if (consignmentCategory === 'international') {
            form.setFieldsValue({
                [NumPieces.key]: Object.keys(pieces).length,
            });
            calculateTotalWeightVolumeCod();
        }
    }, [pieces]);

    const calculateItemTotalWeightVolume = (piece: PieceDetails) => {
        if (showItemTotalWeightAndVolume) {
            const data = form.getFieldsValue();
            const currentPiece = data.pieces[piece.key];

            let weightWithUnit = Number(currentPiece.weight || 0) * Number(currentPiece.numberOfPieces || 1);

            const itemLengthWithUnit = Helper.converttoCentimeter(Number(currentPiece.length || 0),
                currentPiece.dimensionsUnit);

            const itemWidthWithUnit = Helper.converttoCentimeter(Number(currentPiece.width || 0),
                currentPiece.dimensionsUnit);

            const itemHeightWithUnit = Helper.converttoCentimeter(Number(currentPiece.height || 0),
                currentPiece.dimensionsUnit);

            const itemVolumeWithUnit = itemLengthWithUnit * itemWidthWithUnit * itemHeightWithUnit;

            const convertedVolume = convertCentimeterVolumeWithUnit(itemVolumeWithUnit, 'mtr');

            const totalItemVolumeWithUnit: number = Number(currentPiece.numberOfPieces || 1) * convertedVolume;

            weightWithUnit = Number(Helper.converttoKilogram(Number(weightWithUnit), currentPiece.weightUnit));

            let currentItemTotalWeightAndVolume: Record<string, PieceTotals> = {};
            if (itemTotalWeightAndVolume) currentItemTotalWeightAndVolume = { ...itemTotalWeightAndVolume };

            setItemTotalWeightAndVolume({
                ...currentItemTotalWeightAndVolume,
                [piece.key]: {
                    totalWeight: weightWithUnit,
                    totalVolume: totalItemVolumeWithUnit,
                },
            });

            let currentItemsVolume: Record<string, PieceUnitVolume> = {};
            if (itemVolume) currentItemsVolume = { ...itemVolume };

            setItemVolume({
                ...currentItemsVolume,
                [piece.key]: {
                    volume: convertedVolume,
                },
            });
        }
    };

    const calculateTotalAndGrossWeightVolume = (piece: PieceDetails) => {
        calculateItemTotalWeightVolume(piece);
        calculateTotalWeightVolumeCod();
    };

    const loadContents = async () => {
        setLoading(true);
        const response = await getContentList({
            isInternational: consignmentCategory === 'international',
            useCommodityCategory: use_category,
        });
        if (use_category) {
            setCategoryList(response?.data);
        } else {
            setContentList(response?.data);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadContents();
        if (consignmentCategory === 'international') {
            form.setFieldsValue({
                [NumPieces.key]: Object.keys(pieces).length,
            });
        }
        form.setFieldsValue({ [ContentTypes.key]: undefined });
    }, [consignmentCategory]);

    React.useEffect(() => {
        setFormData({
            ...formData,
            contentList,
        });
    }, [contentList]);

    const loadCountries = async () => {
        setLoading(true);
        const countriesResponse = await getCountries();
        if (countriesResponse.isSuccess) {
            setCountryList(countriesResponse.data.map((obj: any) => {
                return {
                    value: obj.country,
                    label: obj.country,
                };
            }));
        }
        setLoading(false);
    };

    const loadCurrencyCodes = async () => {
        setLoading(true);
        const response = await getCurrencyCodes();
        setCurrencyList(response?.data);
        setLoading(false);
    };

    const prefillData = () => {
        form.setFieldsValue({
            ...formData,
            [Unit.key]: formData[Unit.key],
            [Weight.key]: formData[Weight.key],
            [Length.key]: formData[Length.key],
            [Width.key]: formData[Width.key],
            [Height.key]: formData[Height.key],
            [ItemType.key]: formData[ItemType.key],
            [WeightUnit.key]: formData[WeightUnit.key],
            [NumPieces.key]: formData[NumPieces.key],
            [ContentTypes.key]: formData[ContentTypes.key],
            [Description.key]: formData[Description.key],
            [MovementType.key]: formData[MovementType.key],
            [RetailTransaction.key]: formData[RetailTransaction.key],
            [DeclaredValue.key]: formData[DeclaredValue.key],
            [RiskSurcharge.key]: formData[RiskSurcharge.key],
            [CustomerReference.key]: formData[CustomerReference.key],
            [CashonPickup.key]: formData[CashonPickup.key],
            [ConsignmentCategory.key]: formData[ConsignmentCategory.key],
            [VehicleCategory.key]: formData[VehicleCategory.key],
            [ConsignmentReference.key]: formData[ConsignmentReference.key],
            [DeliveryType.key]: formData[DeliveryType.key],
            [EwbNumber.key]: formData[EwbNumber.key],
            [EwbDate.key]: formData[EwbDate.key],
            [ReferenceNumber.key]: formData[ReferenceNumber.key],
            // set data for first time for edit CN
            pieces: formData.pieces,
        });
        setItemType(formData[ItemType.key]);
        setDeliveryType(formData[DeliveryType.key]);
        setConsignmentCategory(formData[ConsignmentCategory.key]);
        setRetailTransactionType(formData[RetailTransaction.key]);
        if (formData.pieces) {
            const updatedPieces: Record<any, PieceDetails> = {};
            Object.keys(formData.pieces).forEach((key) => {
                updatedPieces[key] = {
                    ...formData.pieces[key],
                    key,
                };
            });
            setPieces(updatedPieces);
        } else {
            addPiece();
        }
        if (formData.totalWeightVolumeUnit) {
            setTotalWeightVolumeUnit(formData.totalWeightVolumeUnit);
        }
    };

    const loadAccounts = async () => {
        setLoading(true);
        const result = await getChildList();
        setChildClients(result?.data || []);
        const customer = result?.data?.length ? result.data[0] : undefined;
        form.setFieldsValue({
            [SubAccount.key]: formData[SubAccount.key]
                || `${customer.customer_code}+${customer.customer_id}+${customer.customer_name}`,
        });
        setLoading(false);
    };

    const loadItemUnits = async () => {
        setLoading(true);
        const response = await fetchItemUnits();
        if (response?.data?.length) {
            const data = response?.data || [];
            const itemUnitsToSet = data.map((entry:any) => {
                return {
                    label: entry?.name,
                    value: entry?.code,
                };
            });
            setItemUnits(itemUnitsToSet);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadContents();
        loadAccounts();
        loadCurrencyCodes();
        loadCountries();
        if (showDeliveryType) {
            loadPudoHubs();
        }
        prefillData();
        loadItemUnits();
    }, []);

    const isRequired = (isPiece: any, key: string) => {
        let required = false;
        if (isPiece) {
            required = required || mandatoryPieceDetails[key];
        } else {
            if (consignmentCategory === 'international') {
                required = [
                    formFields.Weight.key,
                    formFields.DeclaredValue.key,
                    formFields.CustomerReference.key,
                ].includes(key);
            }
            if (consignmentCategory === 'international' && itemType === 'non-document') {
                required = required || mandatoryFieldNonDocIntl[key];
            }
            if (consignmentCategory === 'international' && form.getFieldValue('shipmentPurpose') === 'COMMERCIAL') {
                required = required || mandatoryFieldShipmentCommercial[key];
            }
            if (isFTL) {
                required = [
                    formFields.MovementType.key,
                    formFields.VehicleCategory.key,
                ].includes(key);
            }
        }
        return required;
    };

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.line} />;
    };

    const renderTitle = (field: FormField, isPiece: boolean = false) => {
        return (
            <div
                className={classes.title}
                style={field.titleWidth ? { width: field.titleWidth } : {}}
            >
                {t(field.key)}
                {isRequired(isPiece, field.key) ? '*' : null}
            </div>
        );
    };

    const renderInput = (field: FormField, type: string, piece: PieceDetails | undefined) => {
        return (
            <Form.Item
                name={piece ? ['pieces', piece.key, field.key] : field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={isRequired(piece, field.key) ? [formRuleRequired] : undefined}
            >
                <Input
                    type={type}
                    min={0}
                    placeholder={t(field.key)}
                    style={{ width: field.width }}
                    value={piece ? (piece as any)[field.key] : formData[field.key]}
                    disabled={piece && field === Vol_weight && autoCalculateDetails}
                    onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                />
            </Form.Item>
        );
    };

    const renderTextarea = (field: any) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={isRequired(false, field.key) ? [formRuleRequired] : undefined}
            >
                <TextArea
                    placeholder={t(field.placeholder)}
                    style={{ width: field.width }}
                />
            </Form.Item>
        );
    };

    const renderRadio = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                valuePropName={field.valuePropName}
                initialValue={field.defaultValue}
            >
                <Radio.Group
                    style={{ width: field.width }}
                    options={field.options}
                    // defaultValue={field.defaultValue}
                />
            </Form.Item>
        );
    };

    const renderSelect = (field: FormField, piece: PieceDetails | undefined) => {
        if (Array.isArray(field.options) && field.options.length > 0) {
            field.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = i18n.exists(item.value) ? t(item.value) : item.label;
            });
        }
        return (
            <Form.Item
                name={piece ? ['pieces', piece.key, 'dimensionsUnit'] : field.key}
                initialValue={piece ? piece.dimensionsUnit : field.defaultValue}
                valuePropName={field.valuePropName}
                rules={isRequired(piece, field.key) ? [formRuleRequired] : undefined}
            >
                <Select
                    disabled={field.disabled}
                    placeholder={t(field.key)}
                    style={{ width: field.width }}
                    options={field.options}
                    allowClear
                    // defaultValue={piece ? piece.dimensionsUnit : undefined}
                    onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                />
            </Form.Item>
        );
    };

    const renderDate = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={isRequired(false, field.key) ? [formRuleRequired] : undefined}
            >
                <DatePicker
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                />
            </Form.Item>
        );
    };

    const renderFormItem = (field: FormField, type: string = 'text', piece: PieceDetails | undefined = undefined) => {
        switch (field.type) {
            case InputTypes.Input: return renderInput(field, type, piece);
            case InputTypes.Select: return renderSelect(field, piece);
            case InputTypes.Textarea: return renderTextarea(field);
            case InputTypes.Radio: return renderRadio(field);
            case InputTypes.Date: return renderDate(field);
            default: return 'Input is not defined';
        }
    };

    const renderReferenceNumber = () => {
        return (
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(ConsignmentReference.key)}
                </div>
                <Form.Item
                    name={ConsignmentReference.key}
                    className={classes.contentType}
                    initialValue={ConsignmentReference.defaultValue}
                    valuePropName={ConsignmentReference.valuePropName}
                    rules={isRequired(false, ConsignmentReference.key) ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={t('consignment_number_input')}
                        style={{ width: ConsignmentReference.width }}
                        disabled={formData?.isEditingCN}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderSubAccount = () => {
        const optionList = childClients?.map((item: any) => {
            return {
                value: `${item.customer_code}+${item.customer_id}`,
                label: item.customer_name,
            };
        });
        return (
            <div className={classes.formItem} style={{ width: '60%' }}>
                {renderTitle(SubAccount)}
                <Form.Item
                    name={SubAccount.key}
                    className={classes.contentType}
                    initialValue={SubAccount.defaultValue}
                    valuePropName={SubAccount.valuePropName}
                    rules={isRequired(false, SubAccount.key) ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={SubAccount.placeholder}
                        style={{ width: SubAccount.width }}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        disabled={formData?.isEditingCN}
                        options={optionList}
                        filterOption={(query, option: any) => {
                            return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderCustomerReferenceNumber = () => {
        return (
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(CustomerReference.key)}
                    {isRequired(false, CustomerReference.key) ? '*' : null}
                </div>
                <Form.Item
                    name={CustomerReference.key}
                    className={classes.contentType}
                    initialValue={CustomerReference.defaultValue}
                    valuePropName={CustomerReference.valuePropName}
                    rules={isRequired(false, CustomerReference.key) ? [formRuleRequired] : undefined}
                    dependencies={[ConsignmentCategory.key]}
                >
                    <Input
                        placeholder={t('customer_reference_number_input')}
                        style={{ width: CustomerReference.width }}
                        disabled={source === 'plugin'}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderEwbNumber = () => {
        const rules: any[] = [formRules.pattern];

        rules.push({
            pattern: /^[A-Za-z0-9]{12}$/,
            message: 'EWB Number should be alphanumeric & length should be 12',
        });

        if (isRequired(false, EwbNumber.key)) {
            rules.push(formRuleRequired);
        }

        return (
            <div className={classes.formItem}>
                <div className={classes.referenceTitle}>
                    {EwbNumber.label}
                    {isRequired(false, EwbNumber.key) ? '*' : null}
                </div>
                <Form.Item
                    name={EwbNumber.key}
                    className={classes.contentType}
                    initialValue={EwbNumber.defaultValue}
                    valuePropName={EwbNumber.valuePropName}
                    rules={rules}
                >
                    <Input
                        placeholder={EwbNumber.placeholder}
                        style={{ width: EwbNumber.width }}
                    />
                </Form.Item>
            </div>
        );
    };

    const getDisabled = (current: Moment) => {
        return (
            moment(current) >= moment()
        );
    };

    const renderEwbDate = () => {
        return (
            <div className={classes.formItem}>
                <div className={classes.referenceTitle}>
                    {EwbDate.label}
                    {isRequired(false, EwbDate.key) ? '*' : null}
                </div>
                <Form.Item
                    name={EwbDate.key}
                    className={classes.contentType}
                    initialValue={EwbDate.defaultValue}
                    valuePropName={EwbDate.valuePropName}
                    rules={isRequired(false, EwbDate.key) ? [formRuleRequired] : undefined}
                >
                    <DatePicker
                        disabledDate={(current) => getDisabled(current)}
                        placeholder={EwbDate.placeholder}
                        style={{ width: EwbDate.width }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderCashOnPickupAmount = () => {
        const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
        if (config?.parts_to_show.is_cop_applicable) {
            return (
                <div className={classes.referenceItem}>
                    <div className={classes.referenceTitle}>
                        {t(CashonPickup.key)}
                    </div>
                    <Form.Item
                        name={CashonPickup.key}
                        className={classes.contentType}
                        initialValue={CashonPickup.defaultValue}
                        valuePropName={CashonPickup.valuePropName}
                        rules={retailTransactionType === 'retailTransactionTrue' ? [formRuleRequired] : undefined}
                    >
                        <Input
                            type="number"
                            placeholder={CashonPickup.placeholder}
                            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                            style={{ width: CashonPickup.width }}
                            addonAfter="INR"
                        />
                    </Form.Item>
                </div>
            );
        }
        return null;
    };

    const renderReference = () => {
        return (
            <div className={classes.reference}>
                {renderReferenceNumber()}
                {renderCustomerReferenceNumber()}
                {renderCashOnPickupAmount()}
            </div>
        );
    };

    const renderEwb = () => {
        const showEwb = get(config, 'config.customer_portal_config.show_ewb_fields', false);

        if (!showEwb) {
            return null;
        }

        return (
            <div className={classes.boxFields}>
                {renderEwbNumber()}
                {renderEwbDate()}
            </div>
        );
    };

    const renderConsignmentCategory = () => {
        let options = ConsignmentCategory.options;
        if (!config?.parts_to_show?.allow_international_booking) {
            options = options?.filter((option) => option.value !== 'international');
        }
        if (isFTL) {
            options = options?.filter((option) => option.value === 'domestic');
        }
        if (Array.isArray(options) && options.length > 0) {
            options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }
        if (hideConsignmentCategory) return null;
        return (
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(ConsignmentCategory.key)}
                </div>
                <Form.Item
                    name={ConsignmentCategory.key}
                    initialValue={ConsignmentCategory.defaultValue}
                    valuePropName={ConsignmentCategory.valuePropName}
                    rules={isRequired(false, ConsignmentCategory.key) ? [formRuleRequired] : undefined}
                >
                    <Radio.Group
                        options={options}
                        style={{
                            display: 'flex',
                            flexDirection: ConsignmentCategory.direction,
                        }}
                        onChange={(e) => setConsignmentCategory(e.target.value)}
                        disabled={source === 'plugin'}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderItemType = () => {
        const options = loadTypeOptions || ItemType.options;
        if (options) {
            options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }
        return (
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(ItemType.key)}
                </div>
                <Form.Item
                    name={ItemType.key}
                    initialValue={ItemType.defaultValue}
                    valuePropName={ItemType.valuePropName}
                    rules={isRequired(false, ItemType.key) ? [formRuleRequired] : undefined}
                >
                    <Radio.Group
                        options={options}
                        onChange={(e) => setItemType(e.target.value)}
                        disabled={source === 'plugin'}
                        style={{
                            display: 'flex',
                            flexDirection: ItemType.direction,
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderMovementTypes = () => {
        let movementTypeOptions = MovementType.options;
        if (!isFTL) {
            movementTypeOptions = movementTypeOptions?.filter((option) => option.value !== 'ftlhyperlocal');
        }
        if (consignmentCategory === 'domestic') {
            if (config?.parts_to_show?.allow_hyperlocal_cn === false) {
                movementTypeOptions = movementTypeOptions?.filter(
                    (option) => option.value !== 'hyperlocal',
                );
            }
            if (!(consignmentCategory === 'domestic' && config?.Customer?.is_reverse !== true)) {
                movementTypeOptions = movementTypeOptions?.filter(
                    (option) => option.value !== 'forward',
                );
            }
            if (!(consignmentCategory !== 'domestic' || config?.Customer?.is_reverse !== false)) {
                movementTypeOptions = movementTypeOptions?.filter(
                    (option) => option.value !== 'reverse',
                );
            }
            if (isFTL) {
                movementTypeOptions = movementTypeOptions?.filter((option) => option.value === 'ftlhyperlocal');
            }
        } else {
            movementTypeOptions = MovementType.options?.filter((option) => option.value === 'forward');
        }
        if (Array.isArray(movementTypeOptions) && movementTypeOptions.length > 0) {
            movementTypeOptions.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }
        if (isFTL) {
            form.setFieldsValue({
                [MovementType.key]: 'ftlhyperlocal',
            });
        }
        return (
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(MovementType.key)}
                </div>
                <Form.Item
                    name={MovementType.key}
                    initialValue={isFTL ? MovementType.defaultValue2 : MovementType.defaultValue}
                    valuePropName={MovementType.valuePropName}
                    rules={isRequired(false, MovementType.key) ? [formRuleRequired] : undefined}
                >
                    <Radio.Group
                        disabled={formData?.isEditingCN || source === 'plugin'}
                        options={movementTypeOptions}
                        style={{
                            display: 'flex',
                            flexDirection: MovementType.direction,
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderRetailTransaction = () => {
        const retailTransactionTypeOptions = RetailTransaction.options;
        if (Array.isArray(retailTransactionTypeOptions) && retailTransactionTypeOptions.length > 0) {
            retailTransactionTypeOptions.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }
        if (config?.parts_to_show.is_cop_applicable) {
            return (
                <div className={classes.referenceItem}>
                    <div className={classes.referenceTitle}>
                        {t(RetailTransaction.key)}
                    </div>
                    <Form.Item
                        name={RetailTransaction.key}
                        initialValue={RetailTransaction.defaultValue}
                        valuePropName={RetailTransaction.valuePropName}
                    >
                        <Radio.Group
                            options={retailTransactionTypeOptions}
                            onChange={(e) => setRetailTransactionType(e.target.value)}
                            defaultValue={RetailTransaction.defaultValue}
                            style={{
                                display: 'flex',
                                flexDirection: RetailTransaction.direction,
                            }}
                        />
                    </Form.Item>
                </div>
            );
        }
        return null;
    };

    const renderCategoryAndAccount = () => {
        return (
            <div className={classes.reference}>
                {renderConsignmentCategory()}
                {renderSubAccount()}
            </div>
        );
    };

    const changeDeliveryType = (e: any) => {
        const deliveryTypeSelected = e?.target?.value;

        if (deliveryTypeSelected === 'HOME_DELIVERY') {
            form.setFieldsValue({
                [PudoStores.key]: '',
            });
        }
        setDeliveryType(deliveryTypeSelected);
    };

    const renderDeliveryType = () => {
        if (Array.isArray(DeliveryType.options) && DeliveryType.options.length > 0) {
            DeliveryType.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }

        return (
            <div className={classes.deliveryType}>
                <div className={classes.referenceTitle}>
                    {t(DeliveryType.key)}
                </div>
                <Form.Item
                    name={DeliveryType.key}
                    initialValue={DeliveryType.defaultValue}
                    valuePropName={DeliveryType.valuePropName}
                    rules={isRequired(false, DeliveryType.key) ? [formRuleRequired] : undefined}
                >
                    <Radio.Group
                        options={DeliveryType.options}
                        onChange={changeDeliveryType}
                        style={{
                            display: 'flex',
                            flexDirection: DeliveryType.direction,
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderPudoStores = () => {
        if (deliveryType === 'HOME_DELIVERY') {
            return null;
        }
        const optionList = pudoHubs.map((item) => {
            return {
                value: item.code,
                label: item.code,
            };
        });
        return (
            <div className={classes.referenceItem}>
                {renderTitle(PudoStores)}
                <Form.Item
                    name={PudoStores.key}
                    className={classes.contentType}
                    valuePropName={PudoStores.valuePropName}
                    rules={isRequired(false, PudoStores.key) ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={t(PudoStores.key)}
                        style={{ width: PudoStores.width }}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        options={optionList}
                        filterOption={(query, option: any) => {
                            return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderDeliveryTypeAndStore = () => {
        if (!showDeliveryType) return null;

        return (
            <>
                {renderLine()}
                <div className={classes.boxFields}>
                    {renderDeliveryType()}
                    {renderPudoStores()}
                </div>
            </>
        );
    };

    const renderCurrencyUnit = (unitField: FormField) => {
        let options = unitField.options || [];
        if (currencyList.length > 0) {
            options = currencyList.map((currency) => {
                return (
                    {
                        // label: currency.currency_name,
                        label: currency.currency_code,
                        value: currency.currency_code,
                    }
                );
            });
        }
        options.sort((a, b) => ((a.label > b.label) ? 1 : -1));
        return (
            <Form.Item
                name={unitField.key}
                key={unitField.key}
                initialValue={unitField.defaultValue}
            >
                <Select
                    disabled={unitField.disabled}
                    placeholder={unitField.placeholder}
                    options={options}
                    showSearch
                    style={{ width: unitField.width }}
                    showArrow={false}
                    dropdownMatchSelectWidth={false}
                />
            </Form.Item>
        );
    };

    const renderFormTitleAndField = (field: FormField) => {
        if (!fieldsToShowInternational[field.key]) return null;
        return (
            <div className={classes.formItem}>
                { renderTitle(field) }
                { renderFormItem(field, field.type, undefined) }
            </div>
        );
    };

    const renderInternationalDetails = () => {
        const shipmentPurposeDocument = {
            ...shipment_purpose,
            key: 'shipmentPurposeDoc',
            options: [
                {
                    label: 'Document',
                    value: 'DOCUMENT',
                },
            ],
        };
        shipment_purpose.options = Helper.isValidArray(config?.config?.shipment_purpose_list)
            ? config?.config?.shipment_purpose_list?.map((key: string) => {
                return {
                    label: key,
                    value: key,
                };
            })
            : shipment_purpose.options;
        return (
            <>
                <div className={classes.boxFields}>
                    {renderFormTitleAndField(Cust_Seller_code)}
                    {fieldsToShowInternational[shipment_purpose.key]
                        ? (
                            <div className={classes.formItem}>
                                { renderTitle(shipment_purpose) }
                                { itemType === 'non-document'
                                    ? renderFormItem(shipment_purpose, shipment_purpose.type, undefined)
                                    : renderFormItem(shipmentPurposeDocument, shipmentPurposeDocument.type, undefined)}
                            </div>
                        ) : null }
                    {renderFormTitleAndField(shipment_Terms)}
                </div>
                <div className={classes.boxFields}>
                    {fieldsToShowInternational[DeliveryNote.key]
                        ? (
                            <div style={{ width: '95%', marginBottom: 10 }}>
                                { renderTitle(DeliveryNote) }
                                { renderFormItem(DeliveryNote, DeliveryNote.type, undefined) }
                            </div>
                        ) : null }
                    <div style={{ width: 0 }} />
                </div>
                <div className={classes.boxFields}>
                    {renderFormTitleAndField(EORI_No)}
                    {/* {renderFormTitleAndField(IORI_No)} */}
                    {renderFormTitleAndField(Against_Bond_LUT)}
                    {/* Just adding below empty div for alignment */}
                    <div className={classes.formItem} />
                </div>
                <div className={classes.boxFieldsRadio}>
                    {renderFormTitleAndField(ECom_Shipment)}
                    {renderFormTitleAndField(MEIS)}
                    {renderFormTitleAndField(Is_Battery)}
                </div>
            </>
        );
    };

    const renderPrimaryDetails = () => {
        return (
            <div className={classes.boxFieldsCol}>
                <div className={classes.boxFields}>
                    {renderCategoryAndAccount()}
                    {renderMovementTypes()}
                    {renderRetailTransaction()}
                    {renderReference()}
                </div>
                <div>
                    {consignmentCategory === 'international' ? renderInternationalDetails() : null}
                </div>
                {renderDeliveryTypeAndStore()}
                {renderEwb()}
            </div>
        );
    };

    const renderPrimary = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(PrimaryIcon, t('primary_details'))}
                {renderPrimaryDetails()}
            </div>
        );
    };

    const renderWeightUnit = (piece: PieceDetails | undefined) => {
        return (
            <Form.Item
                name={piece ? ['pieces', piece.key, WeightUnit.key] : WeightUnit.key}
                key={WeightUnit.key}
                initialValue={piece ? piece.weightUnit : WeightUnit.defaultValue}
                rules={isRequired(piece, WeightUnit.key) ? [formRuleRequired] : undefined}
            >
                <Select
                    placeholder={WeightUnit.placeholder}
                    options={WeightUnit.options}
                    style={{ width: WeightUnit.width }}
                    showArrow={false}
                    dropdownMatchSelectWidth={false}
                    // defaultValue={piece ? piece.weightUnit : undefined}
                    disabled={!piece && consignmentCategory === 'international'
                        && itemType === 'non-document' && autoCalculateDetails}
                    onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                >
                    {WeightUnit.options?.map((item) => {
                        return (
                            <Select.Option
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    const renderWeight = (piece: PieceDetails| undefined) => {
        return (
            <div className={classes.formItemWeight}>
                {renderTitle(Weight, !!piece)}
                <Form.Item
                    name={piece ? ['pieces', piece.key, Weight.key] : Weight.key}
                    className={classes.contentType}
                    initialValue={Weight.defaultValue}
                    valuePropName={Weight.valuePropName}
                    style={{ width: 250 }}
                    rules={isRequired(piece, Weight.key) ? [formRuleRequired] : undefined}
                >
                    <Input
                        type="number"
                        size="small"
                        min={0}
                        placeholder={t(Weight.key)}
                        style={{ width: Weight.width }}
                        value={piece ? piece.weight : formData[Weight.key]}
                        onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                        addonAfter={renderWeightUnit(piece)}
                        disabled={!piece && consignmentCategory === 'international'
                            && itemType === 'non-document' && autoCalculateDetails}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderTotalItemWeightAndVolumeUnit = (field: string) => {
        const volumeLabel = '\u33A5';
        const weightLabel = 'kg';

        return (
            <Select
                disabled
                style={{ width: WeightUnit.width }}
                showArrow={false}
                dropdownMatchSelectWidth={false}
                value={field === 'weight' ? weightLabel : volumeLabel}
            />
        );
    };

    const renderVolume = (piece: PieceDetails | undefined) => {
        return (
            <Input
                style={{ width: 150, marginLeft: '2px' }}
                value={itemVolume[piece?.key]?.volume}
                placeholder={t('volume')}
                addonAfter={renderTotalItemWeightAndVolumeUnit('volume')}
                disabled
            />
        );
    };

    const renderDimensions = (piece: PieceDetails | undefined) => {
        if (itemType === 'non-document') {
            return (
                <div className={classes.dimensions}>
                    <div className={classes.dimentionTitle}>
                        {t('dimensions')}
                        {
                            isRequired(piece, Length.key)
                            || isRequired(piece, Width.key)
                            || isRequired(piece, Height.key)
                                ? '*' : ''
                        }
                    </div>
                    <Space direction="horizontal">
                        {renderFormItem(Length, 'number', piece)}
                        {renderFormItem(Width, 'number', piece)}
                        {renderFormItem(Height, 'number', piece)}
                        {renderFormItem(Unit, 'number', piece)}
                        {showItemTotalWeightAndVolume && renderVolume(piece)}
                    </Space>
                </div>
            );
        }
        return null;
    };

    const renderWeightDimenstion = (piece: PieceDetails | undefined) => {
        return (
            <div className={classes.boxFields}>
                {renderWeight(piece)}
                {renderDimensions(piece)}
            </div>
        );
    };

    const renderTotalItemWeightAndVolume = (piece: PieceDetails | undefined) => {
        return (
            <div className={classes.boxFields}>
                <div className={classes.formItem}>
                    <div className={classes.title}>
                        {t('consignment_total_weight')}
                    </div>
                    <Input
                        className={classes.contentType}
                        value={itemTotalWeightAndVolume[piece?.key]?.totalWeight}
                        placeholder={t('consignment_total_weight')}
                        addonAfter={renderTotalItemWeightAndVolumeUnit('weight')}
                        disabled
                    />
                </div>
                <div className={classes.formItem}>
                    <div className={classes.title}>
                        {t('consignment_total_volume')}
                    </div>
                    <Input
                        className={classes.contentType}
                        value={itemTotalWeightAndVolume[piece?.key]?.totalVolume}
                        placeholder={t('consignment_total_volume')}
                        addonAfter={renderTotalItemWeightAndVolumeUnit('volume')}
                        disabled
                    />
                </div>
            </div>
        );
    };

    const renderCurrencyPiece = () => {
        return (
            <Select
                disabled
                dropdownMatchSelectWidth={false}
                placeholder={formData.currency}
            >
                <Select.Option key={formData.currency} value={formData.currency}>
                    {formData.currency}
                </Select.Option>
            </Select>
        );
    };

    const renderCodAmount = (piece: PieceDetails) => {
        if (!itemPartialDelivery) return (<></>);

        return (
            <div className={classes.boxFields}>
                <div className={classes.formItem}>
                    {renderTitle(Amount)}
                    <Form.Item
                        name={['pieces', piece.key, Amount.key]}
                        className={classes.contentType}
                        initialValue={Amount.defaultValue}
                        valuePropName={Amount.valuePropName}
                        rules={piece && mandatoryPieceDetails[Amount.key] ? [formRuleRequired] : undefined}
                    >
                        <Input
                            type="number"
                            size="small"
                            min={0}
                            placeholder={t('cod_amount_placeholder')}
                            style={{ width: Amount.width }}
                            value={piece.codAmount}
                            onChange={() => calculateTotalWeightVolumeCod()}
                            addonAfter={renderCurrencyPiece()}
                        />
                    </Form.Item>
                </div>
            </div>
        );
    };

    const renderDeclaredCurrency = () => {
        if (!showDeclaredCurrencyField) return null;
        return (
            <Form.Item
                name={DeclaredValueCurrency.key}
                valuePropName={DeclaredValueCurrency.valuePropName}
            >
                <Select
                    options={declaredCurrenciesList.map((curr: string) => {
                        return {
                            value: curr,
                            label: curr,
                        };
                    })}
                    placeholder={DeclaredValueCurrency.placeholder}
                    style={{ width: DeclaredValueCurrency.width }}
                    dropdownMatchSelectWidth={false}
                    showArrow={false}
                    showSearch
                />
            </Form.Item>
        );
    };

    const renderDeclaredValue = () => {
        if (
            itemType === 'non-document'
            || (showDeclearedPriceInDocumentInt && consignmentCategory === 'international')
            || isFTL
        ) {
            return (
                <div className={classes.formItem}>
                    {renderTitle(DeclaredValue)}
                    <Form.Item
                        name={DeclaredValue.key}
                        className={classes.contentType}
                        valuePropName={DeclaredValue.valuePropName}
                        rules={isRequired(false, DeclaredValue.key) ? [formRuleRequired] : undefined}
                    >
                        <Input
                            type="number"
                            placeholder={t(DeclaredValue.key)}
                            style={{ width: DeclaredValue.width }}
                            disabled={consignmentCategory === 'international' && itemType === 'non-document'
                                && autoCalculateDetails}
                            // eslint-disable-next-line no-nested-ternary
                            addonAfter={consignmentCategory === 'international'
                                ? renderCurrencyUnit(Currency)
                                : (showDeclaredCurrencyField ? renderDeclaredCurrency() : undefined)}
                            onChange={(e) => setChangeData(e.target.value)}
                        />
                    </Form.Item>
                </div>
            );
        }
        return null;
    };

    const renderRiskSurcharge = () => {
        if (itemType === 'non-document') {
            return (
                <div className={classes.formItem}>
                    <Form.Item
                        name={RiskSurcharge.key}
                        className={classes.contentType}
                        initialValue={RiskSurcharge.defaultValue}
                        valuePropName={RiskSurcharge.valuePropName}
                        dependencies={[DeclaredValue.key]}
                    >
                        <Checkbox disabled={disableRiskSurcharge}>
                            {t(RiskSurcharge.key)}
                        </Checkbox>
                    </Form.Item>
                </div>
            );
        }
        return null;
    };

    const renderDescription = (piece: PieceDetails | undefined) => {
        return (
            <div className={classes.description}>
                <div className={classes.descriptionTitle}>
                    {t(Description.key)}
                </div>
                <Form.Item
                    name={piece ? ['pieces', piece.key, Description.key] : Description.key}
                    initialValue={Description.defaultValue}
                    valuePropName={Description.valuePropName}
                    rules={isRequired(piece, Description.key) ? [formRuleRequired] : undefined}
                >
                    <TextArea
                        placeholder={t(Description.key)}
                        style={{ width: Description.width }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderDeclaredValueAndRisk = () => {
        if (itemType === 'non-document') {
            return (
                <>
                    <div className={classes.boxFields}>
                        {renderDeclaredValue()}
                        {renderRiskSurcharge()}
                    </div>
                    {
                        config?.Customer?.is_cpdp
                        && config?.config?.customer_portal_config?.show_risk_surcharge_type
                        && consignmentCategory !== 'international'
                            ? (
                                <div className={classes.description}>
                                    <div className={classes.descriptionTitle}>
                                        Risk Surcharge Type
                                    </div>
                                    <Space direction="vertical" style={{ display: 'block' }}>
                                        <Form.Item
                                            name="riskSurchargeType"
                                        >
                                            <Radio.Group
                                                options={riskOptions}
                                                disabled={!riskSurchargeValue}
                                            />
                                        </Form.Item>
                                    </Space>
                                </div>
                            ) : null
                    }
                </>
            );
        }
        if (showDeclearedPriceInDocumentInt && consignmentCategory === 'international') {
            return (
                <div className={classes.boxFields}>
                    {renderDeclaredValue()}
                </div>
            );
        }
        return null;
    };

    const renderNumPeices = (piece: PieceDetails | undefined) => {
        return (
            <div className={classes.formItem}>
                <div
                    className={classes.title}
                    style={NumPieces.titleWidth ? { width: NumPieces.titleWidth } : {}}
                >
                    {piece ? t('num_pieces_label_2') : t('num_pieces_label')}
                    {isRequired(piece, NumPieces.key) ? '*' : ''}
                </div>
                <Form.Item
                    name={piece ? ['pieces', piece.key, NumPieces.key] : NumPieces.key}
                    className={classes.contentType}
                    initialValue={NumPieces.defaultValue}
                    valuePropName={NumPieces.valuePropName}
                    rules={isRequired(piece, NumPieces.key)
                        ? [formRuleRequired, formRules.maxNumber1000]
                        : [formRules.maxNumber1000]}
                >
                    <Input
                        type="number"
                        min={0}
                        max={1000}
                        placeholder={piece ? t('num_pieces_label_2') : t('num_pieces_label')}
                        style={{ width: NumPieces.width }}
                        value={piece ? (piece.numberOfPieces || 1) : formData[NumPieces.key]}
                        disabled={
                            piece?.key?.startsWith('pieceOld')
                            || (!piece && consignmentCategory === 'international' && autoCalculateDetails)
                        }
                        onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                    />
                </Form.Item>
            </div>
        );
    };
    const filter = (inputValue: string, path: any) => {
        return path.some(
            (option: { label: string; }) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );
    };

    const renderContentType = () => {
        const categories: { value: string; label: string; children: { value: string; label: string; }[] }[] = [];
        const captureOtherCommodityIdList: string[] = [];
        categoryList.forEach((category) => {
            const commodities: { value: string; label: string; }[] = [];
            category.commodityList?.forEach((item) => {
                commodities.push({
                    value: item.COMMODITY_ID,
                    label: item.COMMODITY_NAME,
                });
                if (item.captureActualCommodity) {
                    captureOtherCommodityIdList.push(item.COMMODITY_ID as string);
                }
            });
            categories.push({
                value: category.categoryId,
                label: i18n.exists(category.categoryName.toUpperCase())
                    ? t(category.categoryName.toUpperCase()) : category.categoryName,
                children: commodities,
            });
        });
        const optionList = use_category ? categories : contentList.map((item) => {
            return {
                value: item.COMMODITY_ID,
                label: i18n.exists(item.COMMODITY_NAME.toUpperCase())
                    ? t(item.COMMODITY_NAME.toUpperCase()) : item.COMMODITY_NAME,
            };
        });
        return (
            <div className={classes.formItem}>
                <div className={classes.title}>
                    {use_category ? t('content_type_label_2') : t('content_type_label')}
                    {isRequired(false, ContentTypes.key) ? '*' : ''}
                </div>
                <Form.Item
                    name={ContentTypes.key}
                    className={classes.contentType}
                    initialValue={ContentTypes.defaultValue}
                    valuePropName={ContentTypes.valuePropName}
                    rules={isRequired(false, ContentTypes.key) ? [formRuleRequired] : undefined}
                >
                    <Cascader
                        placeholder={use_category ? t('content_type_label_2') : t('content_type_label')}
                        style={{ width: ContentTypes.width }}
                        showSearch={{ filter }}
                        allowClear
                        options={optionList}
                        onChange={(commodity) => {
                            const id = (Helper.isValidArray(commodity) ? last(commodity) : '') as string;
                            let isOtherReq = false;
                            if (use_category) {
                                if (captureOtherCommodityIdList.includes(id)) {
                                    setShowOtherName(true);
                                    isOtherReq = true;
                                    setFormData({ ...formData, showOtherName: true });
                                }
                            } else {
                                contentList?.forEach((content) => {
                                    if (content?.COMMODITY_ID === id && content?.captureActualCommodity) {
                                        setShowOtherName(true);
                                        isOtherReq = true;
                                        setFormData({ ...formData, showOtherName: true });
                                    }
                                });
                            }
                            if (!isOtherReq) {
                                form.setFieldsValue({ [ContentNameForOthers.key]: undefined });
                                setShowOtherName(false);
                                setFormData({ ...formData, showOtherName: false });
                            }
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderActualCommodityName = () => {
        return (
            <div className={classes.formItem}>
                <div className={classes.title} style={{ width: '100%' }}>
                    {ContentNameForOthers.label}
                </div>
                <Form.Item
                    name={ContentNameForOthers.key}
                    className={classes.contentType}
                    initialValue={ContentNameForOthers.defaultValue}
                    valuePropName={ContentNameForOthers.valuePropName}
                >
                    <Input
                        placeholder={ContentNameForOthers.placeholder}
                        style={{ width: ContentTypes.width }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderPieceReferenceNumber = (piece: PieceDetails) => {
        let shouldFieldBeDisabled = true;
        if (!separateChildCN && !piece.key?.startsWith('pieceOld')) {
            shouldFieldBeDisabled = false;
        }
        return (
            <div className={classes.formItem}>
                <div
                    className={classes.title}
                    style={{ width: ReferenceNumber.width }}
                >
                    {t('piece_reference_no')}
                    {isRequired(piece, ReferenceNumber.key) ? '*' : ''}
                </div>
                <Form.Item
                    name={piece ? ['pieces', piece.key, ReferenceNumber.key] : ReferenceNumber.key}
                    className={classes.contentType}
                    initialValue={piece.reference_number}
                    valuePropName={ReferenceNumber.valuePropName}
                    rules={
                        isRequired(piece, ReferenceNumber.key) ? [formRuleRequired, formRules.number] : [formRules.number]
                    }
                >
                    <Input
                        type="number"
                        min={0}
                        style={{ width: ReferenceNumber.width }}
                        value={piece ? piece.reference_number : formData[ReferenceNumber.key]}
                        disabled={shouldFieldBeDisabled}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderUnit = (piece: PieceDetails) => {
        let optionList = [];
        if (itemUnits.length) {
            optionList = itemUnits;
        } else {
            optionList = allowedUnits.map((item: any) => {
                return {
                    value: item,
                    label: item,
                };
            });
        }

        return (
            <div className={classes.formItem}>
                <div className={classes.title}>
                    {t('units')}
                </div>
                <Form.Item
                    name={['pieces', piece.key, 'unit']}
                    className={classes.contentType}
                    valuePropName={ContentTypes.valuePropName}
                    initialValue={piece?.unit}
                    rules={isRequired(piece, 'unit') ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={t('units')}
                        style={{ width: ContentTypes.width }}
                        allowClear
                        options={optionList}
                        // defaultValue={piece?.unit}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderRemovePiece = (id: any) => {
        return (
            <Button danger type="link" onClick={() => removePiece(id)}>{t('remove')}</Button>
        );
    };
    const renderMultipleContentAndPieces = () => {
        if (!pieces) {
            return null;
        }
        return (
            <>
                {
                    Object.keys(pieces).map((key) => {
                        const piece = pieces[key];
                        return (
                            <div key={piece.key}>
                                <div className={classes.boxFields} style={{ justifyContent: 'space-between' }}>
                                    { renderPieceReferenceNumber(piece) }
                                    { Object.keys(pieces).length !== 1 ? renderRemovePiece(piece.key) : null}
                                </div>
                                <div className={classes.boxFields}>
                                    { renderNumPeices(piece)}
                                    { showCustomFields && customFields.units ? renderUnit(piece) : null}
                                </div>
                                { renderWeightDimenstion(piece) }
                                { showItemTotalWeightAndVolume && renderTotalItemWeightAndVolume(piece) }
                                { renderCodAmount(piece) }
                                {renderLine()}
                            </div>
                        );
                    })
                }
                <Button type="primary" onClick={() => addPiece()} style={{ margin: '10px 0' }}>{t('add_more_text')}</Button>
            </>
        );
    };

    const renderSingleContentAndPiece = () => {
        return (
            <>
                <div className={classes.boxFields} style={{ flexWrap: 'wrap' }}>
                    { (showCustomFields && !customFields.content_type) ? null : renderContentType()}
                    { showOtherName ? renderActualCommodityName() : null }
                    {renderNumPeices(undefined)}
                </div>
                {renderWeightDimenstion(undefined)}
            </>
        );
    };

    const renderContentAndPieces = () => {
        if (itemType === 'non-document') {
            return (
                <div>
                    { consignmentConfig?.allow_multiple_piece_dimensions
                        ? renderMultipleContentAndPieces() : renderSingleContentAndPiece() }
                </div>
            );
        }
        return renderWeight(undefined);
    };

    const renderTotalWeightAndVolumeUnit = (field: string) => {
        const volumeOptions = [{
            label: '\u33A4',
            value: 'cm',
        }, {
            label: '\u33A5',
            value: 'm',
        }];
        return (
            <Select
                disabled={WeightUnit.disabled}
                placeholder={WeightUnit.placeholder}
                options={field === 'weight' ? WeightUnit.options : volumeOptions}
                style={{ width: WeightUnit.width }}
                showArrow={false}
                dropdownMatchSelectWidth={false}
                value={totalWeightVolumeUnit[field]}
                onChange={
                    (e) => {
                        setTotalWeightVolumeUnit({
                            ...totalWeightVolumeUnit,
                            [field]: e,
                        });
                    }
                }
            />
        );
    };

    const renderTotalWeightAndVolume = () => {
        return (
            <div className={classes.boxFields}>
                <div className={classes.formItem}>
                    <div className={classes.title}>
                        {showItemTotalWeightAndVolume ? 'Gross Weight' : 'Total Weight'}
                    </div>
                    <Input
                        className={classes.contentType}
                        value={totalWeightVolumeUnit.weight === 'kg' ? totalWeight : totalWeight * 1000}
                        addonAfter={renderTotalWeightAndVolumeUnit('weight')}
                        disabled
                    />
                </div>
                <div className={classes.formItem}>
                    <div className={classes.title}>
                        {showItemTotalWeightAndVolume ? 'Gross Volume' : 'Total Volume'}
                    </div>
                    <Input
                        className={classes.contentType}
                        value={totalWeightVolumeUnit.volume === 'cm' ? totalVolume : totalVolume / 1000000}
                        addonAfter={renderTotalWeightAndVolumeUnit('volume')}
                        disabled
                    />
                </div>
            </div>
        );
    };

    const renderItemDetail = (item: any, field: FormField) => {
        return (
            <span
                style={{
                    marginRight: 10,
                }}
            >
                <span style={{
                    color: '#262626',
                    fontWeight: 300,
                }}
                >
                    {t(field.key)}
                    &nbsp;
                    :
                    &nbsp;
                </span>
                <span style={{
                    color: '#111111',
                    fontWeight: 600,
                }}
                >
                    {item[field.key] || <span style={{ margin: '0 20px' }}>&nbsp;</span> }
                </span>
            </span>
        );
    };

    const renderBoxDetails = () => {
        return (
            <div>
                {renderLine()}
                {
                    Object.keys(pieces).map((key, idx) => {
                        const piece = pieces[key];
                        return (
                            <div
                                key={piece.key}
                                style={{
                                    background: '#FAFAFA',
                                    padding: 4,
                                }}
                            >
                                <div style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}
                                >
                                    Box&nbsp;
                                    {idx + 1}
                                    { Object.keys(pieces).length !== 1 ? renderRemovePiece(piece.key) : null}
                                </div>
                                <div className={classes.boxFields}>
                                    {/* <div className={classes.formItem}>
                                        { renderTitle(BoxNumber) }
                                        { renderFormItem(BoxNumber, 'number', piece) }
                                    </div> */}
                                    { renderNumPeices(piece) }
                                    <div className={classes.formItem}>
                                        { renderTitle(DeclaredValue, !!piece) }
                                        { renderFormItem(DeclaredValue, 'number', piece) }
                                    </div>
                                    {/* { renderUnit(piece)} */}
                                </div>
                                { renderWeightDimenstion(piece) }
                                <div className={classes.boxFields}>
                                    <div className={classes.formItem}>
                                        { renderTitle(Vol_weight, !!piece) }
                                        { renderFormItem(Vol_weight, 'number', piece) }
                                    </div>
                                </div>
                                { renderDescription(piece)}
                                { renderLine() }
                                {
                                    Object.keys(get(piece, 'items', {})).map((itemKey: any, itemIdx: any) => {
                                        let item = get(piece?.items, itemKey, {});
                                        item = {
                                            ...item,
                                            key: itemKey,
                                        };
                                        return (
                                            <div
                                                key={item.key}
                                            >
                                                <div style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                    // marginBottom: 4,
                                                }}
                                                >
                                                    Item&nbsp;
                                                    {itemIdx + 1}
                                                    <Button
                                                        onClick={() => removeItem(piece.key, item.key)}
                                                        danger
                                                        type="link"
                                                        style={{
                                                            fontWeight: '300',
                                                            fontSize: 10,
                                                        }}
                                                    >
                                                        {t('remove')}
                                                    </Button>
                                                </div>
                                                {renderItemDetail(item, itemFormFields.HSNCode)}
                                                {renderItemDetail(item, itemFormFields.Item_Rate)}
                                                {renderItemDetail(item, itemFormFields.Gst_Value)}
                                                &#62;&#62;
                                                <Button
                                                    onClick={() => {
                                                        setSelectedPieceKey(piece.key);
                                                        setSelectedItem(item.key);
                                                    }}
                                                    type="link"
                                                >
                                                    {t('edit_view_more')}
                                                </Button>
                                            </div>
                                        );
                                    })
                                }
                                <div>
                                    {/* Add More Item : */}
                                    {/* <InputNumber
                                        style={{ margin: '0 10px' }}
                                        min={0}
                                        max={10}
                                        placeholder="Write here"
                                        defaultValue={1}
                                    /> */}
                                    <Button
                                        onClick={() => {
                                            setSelectedPieceKey(piece.key);
                                            setSelectedItem(uniqueId('Item'));
                                        }}
                                        type="primary"
                                    >
                                        {t('add_item')}
                                    </Button>
                                </div>
                                { renderLine() }
                            </div>
                        );
                    })
                }
                {t('add_more_boxes')}
                <InputNumber
                    style={{ margin: '0 10px' }}
                    min={0}
                    max={10}
                    placeholder="Write here"
                    defaultValue={1}
                    value={addMoreBoxes}
                    onChange={(e) => {
                        if (e) setAddMoreBoxes(e);
                    }}
                />
                <Button
                    type="primary"
                    onClick={() => setStartAddMoreBoxes(addMoreBoxes)}
                >
                    {t('add_more_text')}
                </Button>
            </div>
        );
    };

    const renderDimensionWeightInfo = () => {
        return (
            <div style={{
                fontSize: '12px', fontFamily: 'Open Sans', fontWeight: '600', fontStyle: 'italic', marginBottom: '10px',
            }}
            >
                &#9432;
                {
                    consignmentCategory === 'international'
                        ? t('dimension_info_international')
                        : t('dimension_info_domestic')
                }
            </div>
        );
    };

    const renderVehicleCategories = () => {
        const vehicleCategories = config?.config?.vehicle_category_list || [];
        const vehicleCategoryOptions = vehicleCategories.map((vehicle: any) => {
            return {
                value: vehicle.name,
                label: vehicle.name,
            };
        });
        return (
            <div className={classes.referenceItem}>
                <div className={classes.vehicleCategoryTitle}>
                    {t(VehicleCategory.label)}
                </div>
                <Form.Item
                    name={VehicleCategory.key}
                    className={classes.contentType}
                    valuePropName={VehicleCategory.valuePropName}
                    rules={isRequired(false, VehicleCategory.key) ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={VehicleCategory.placeholder}
                        style={{ width: VehicleCategory.width }}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        options={vehicleCategoryOptions}
                        filterOption={(query, option: any) => {
                            return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderQuantity = () => {
        return (
            <div className={classes.description}>
                <div className={classes.vehicleCategoryTitle}>
                    {t(noOfItems.label)}
                </div>
                <Form.Item
                    name={noOfItems.key}
                    initialValue={noOfItems.defaultValue || 1}
                    valuePropName={noOfItems.valuePropName}
                    rules={isRequired(false, noOfItems.key) ? [formRuleRequired] : undefined}
                >
                    <Input
                        min={0}
                        value={1}
                        disabled
                        placeholder={t(noOfItems.key)}
                        style={{ width: noOfItems.width }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderFTLContentDetails = () => {
        if (!isFTL) {
            return null;
        }

        return (
            <div className={classes.boxFieldsCol}>
                {renderDeclaredValue()}
                <Row gutter={26}>
                    <Col span={14}>
                        {renderQuantity()}
                    </Col>
                    <Col span={10}>
                        {renderVehicleCategories()}
                    </Col>
                </Row>
                {renderDescription(undefined)}
            </div>
        );
    };

    const renderContentDetails = () => {
        if (isFTL) {
            return null;
        }
        if (consignmentConfig?.single_consignment_creation_fields) {
            (consignmentConfig?.single_consignment_creation_fields || {}).forEach((field: Record<any, any>) => {
                customFields[field.id] = field;
            });
        }
        return (
            <div className={classes.boxFieldsCol}>
                {showItemType ? renderItemType() : null}
                {showItemType ? renderLine() : null}
                {renderDimensionWeightInfo()}
                {renderContentAndPieces()}
                {renderDeclaredValueAndRisk()}
                {showTotalWeightAndVolume ? renderTotalWeightAndVolume() : null}
                {renderDescription(undefined)}
                {consignmentCategory === 'international' && itemType === 'non-document'
                    ? renderBoxDetails() : null}
            </div>
        );
    };

    const renderContents = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(ContentsIcon, t('item_details'))}
                {renderContentDetails()}
                {renderFTLContentDetails()}
            </div>
        );
    };

    return (
        <div className={classes.main}>
            {loading && <Loader zIndex={10} />}
            {renderPrimary()}
            { selectedItem
                ? (
                    <ItemDetails
                        selectedItem={selectedItem}
                        onClose={() => {
                            setSelectedItem(null);
                            setSelectedPieceKey(null);
                        }}
                        formData={formData}
                        setFormData={setFormData}
                        pieces={pieces}
                        setPieces={setPieces}
                        selectedPieceKey={selectedPieceKey}
                        form={form}
                        mandatoryItemDetails={
                            {
                                ...(customerPortalConfig?.mandatory_item_details || {}),
                                gstPercentage: form.getFieldValue(Against_Bond_LUT.key) === 'G',
                                gstValue: form.getFieldValue(Against_Bond_LUT.key) === 'G',
                            }
                        }
                        itemDetailsToShow={customerPortalConfig?.item_details_show || {}}
                        countryList={countryList}
                    />
                )
                : null }
            {renderLine()}
            {renderContents()}
            {renderLine()}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show } = master;
    const show_delivery_type_in_customerportal = get(parts_to_show, 'show_delivery_type_in_customerportal', false);
    return {
        showDeliveryType: show_delivery_type_in_customerportal,
        loadTypeOptions: master.config.load_type_options,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: stepOneStyles,
    },
};

export default GenericHoc(hocConfig)(StepOne);
