import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { useTranslation } from 'react-i18next';
import {
    Col,
    DatePicker,
    Form,
    FormInstance,
    Input,
    Radio,
    Select,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import { formRules } from 'library/constants';
import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    formItemWeight: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    dateStyle: {
        width: '100%',
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    field: FormField;
    parentObj: string;
    MAX_LIMIT: number;
}

const GenericField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        params,
        form,
        field,
        master,
        parentObj,
        MAX_LIMIT,
    } = props;
    const {
        isRequired,
        pieceIndex,
        key,
        itemIndex,
        enableCustomFilter = false,
    } = params;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const isItemLevel = itemIndex !== null && itemIndex !== undefined;
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;
    let formFieldKey = null;
    if (isItemLevel) {
        formFieldKey = [parentObj, itemIndex, field.key];
    } else if (parentObj) {
        formFieldKey = [parentObj, field.key];
    } else {
        formFieldKey = field.key;
    }
    const fieldFormValue = Form.useWatch(key || isPieceLevel ? ['pieces', pieceIndex, field.key] : field.key, form);

    const handleDisabledPickupDate = (currentDate: any) => {
        if (!params.shouldNotSelectPastDate) {
            return false;
        }
        return currentDate && currentDate.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
    };

    const renderTitle = (fieldData: FormField) => {
        return (
            <div
                className={classes.title}
            >
                {t(fieldData.key) || (fieldData.titleKey ? t(fieldData.titleKey) : fieldData.label) || ''}
                {isRequired(isPieceLevel, fieldData.key) ? '*' : null}
            </div>
        );
    };

    const renderInput = (fieldData: FormField) => {
        const isNumber = fieldData.inputType === 'number';
        const isMandatory = isRequired(isPieceLevel, fieldData.key);
        let rulesList: any[] = [];
        if (isMandatory) {
            rulesList = [formRuleRequired];
        }
        if (MAX_LIMIT === 100 && isNumber) {
            if (rulesList) {
                rulesList = rulesList.concat([formRules.maxNumber100]);
            } else {
                rulesList = [formRules.maxNumber100];
            }
        }
        return (
            <Form.Item
                name={key || formFieldKey}
                initialValue={fieldData.defaultValue}
                valuePropName={fieldData.valuePropName}
                rules={rulesList}
            >
                <Input
                    type={fieldData.inputType || 'text'}
                    min={0}
                    placeholder={t(fieldData.key)}
                    value={fieldFormValue}
                />
            </Form.Item>
        );
    };

    const renderTextarea = (fieldData: any) => {
        return (
            <Form.Item
                name={key || formFieldKey}
                initialValue={fieldData.defaultValue}
                valuePropName={fieldData.valuePropName}
                rules={isRequired(isPieceLevel, fieldData.key) ? [formRuleRequired] : undefined}
            >
                <TextArea
                    placeholder={t(fieldData.placeholder)}
                />
            </Form.Item>
        );
    };

    const renderRadio = (fieldData: FormField) => {
        return (
            <Form.Item
                name={key || formFieldKey}
                valuePropName={fieldData.valuePropName}
                initialValue={fieldData.defaultValue}
            >
                <Radio.Group
                    options={fieldData.options}
                    defaultValue={fieldData.defaultValue}
                />
            </Form.Item>
        );
    };

    const renderSelect = (fieldData: FormField) => {
        const options = fieldData.getOptions
            ? (fieldData.getOptions(master) || fieldData.options)
            : fieldData.options;
        if (Array.isArray(options) && options.length > 0) {
            options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = i18n.exists(item.value) ? t(item.value) : item.label;
            });
        }
        return (
            <Form.Item
                name={key || formFieldKey}
                initialValue={fieldData.defaultValue}
                valuePropName={fieldData.valuePropName}
                rules={isRequired(isPieceLevel, fieldData.key) ? [formRuleRequired] : undefined}
            >
                <Select
                    showSearch
                    disabled={fieldData.disabled}
                    placeholder={t(fieldData.key)}
                    options={fieldData.options}
                    allowClear
                    filterOption={
                        enableCustomFilter
                            ? (input: any, option: any) => {
                                return (
                                    option?.value?.toString().toLowerCase().includes(input.toLowerCase())
                                    || option?.label?.toString().toLowerCase().includes(input.toLowerCase())
                                );
                            }
                            : undefined
                    }
                />
            </Form.Item>
        );
    };

    const renderDate = (fieldData: FormField) => {
        return (
            <Form.Item
                name={key || formFieldKey}
                initialValue={fieldData.defaultValue}
                valuePropName={fieldData.valuePropName}
                rules={isRequired(isPieceLevel, fieldData.key) ? [formRuleRequired] : undefined}
            >
                <DatePicker
                    className={classes.dateStyle}
                    placeholder={fieldData.placeholder}
                    disabledDate={handleDisabledPickupDate}
                />
            </Form.Item>
        );
    };

    const renderFormItem = (
        fieldData: FormField,
    ) => {
        switch (fieldData.type) {
            case InputTypes.Input: return renderInput(fieldData);
            case InputTypes.Number: return renderInput({
                ...fieldData,
                inputType: 'number',
            });
            case InputTypes.Select: return renderSelect(fieldData);
            case InputTypes.Textarea: return renderTextarea(fieldData);
            case InputTypes.Radio: return renderRadio(fieldData);
            case InputTypes.Date: return renderDate(fieldData);
            default: return 'Input is not defined';
        }
    };

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                { renderTitle(field) }
                { renderFormItem(field) }
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    return {
        master,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(GenericField);
