import { API_BASE_URL } from 'library/globals';
import { POST } from './api-hander.service';
import { FETCH_PURCHASE_ORDERS, FETCH_PURCHASE_ORDER_DETAILS } from './api.constants';

export const getPurchaseOrders = async (params: any) => {
    return POST(`${API_BASE_URL}${FETCH_PURCHASE_ORDERS}`, params);
};

export const getPurchaseOrderDetails = async (body: any) => {
    return POST(`${API_BASE_URL}${FETCH_PURCHASE_ORDER_DETAILS}`, body);
};

