/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Purchase = (props: any) => (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M6.75008 3.08358H3.25008M6.75008 5.41691H3.25008M6.75008 7.75024H4.41675M0.916748 0.750244H9.08342V11.2502L8.48142 10.7346C8.26997 10.5534 8.00068 10.4538 7.72221 10.4538C7.44373 10.4538 7.17444 10.5534 6.963 10.7346L6.361 11.2502L5.75958 10.7346C5.5481 10.5532 5.27869 10.4535 5.00008 10.4535C4.72147 10.4535 4.45207 10.5532 4.24058 10.7346L3.63916 11.2502L3.03716 10.7346C2.82572 10.5534 2.55643 10.4538 2.27796 10.4538C1.99948 10.4538 1.73019 10.5534 1.51875 10.7346L0.916748 11.2502V0.750244Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

const PurchaseIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Purchase({ ...innerProps, ...props });
    }}
    />
);

export default PurchaseIcon;
