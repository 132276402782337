import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
} from './generic-action';
import { getPurchaseOrders } from 'network/purchase.api';
import moment, { Moment } from 'moment';

const bucket = Buckets.PURCHASE_ORDER;

export const loadOrders = (
    search: string,
    dateRange: [Moment, Moment],
    childCustomerCode: string,
) => async (dispatch: Dispatch<any>, store: () => ReduxStore) => {
    dispatch(startLoading(bucket));

    dispatch(setGenericData(bucket, []));

    const { generic } = store();
    const { PURCHASE_ORDER } = generic;
    const { pagination } = PURCHASE_ORDER;

    const response = await getPurchaseOrders({
        resultPerPage: pagination.resultPerPage,
        pageNumber: pagination.currentPageNumber,
        referenceNumber: search || undefined,
        fromDate: moment(dateRange[0]).format('YYYY-MM-DD'),
        toDate: moment(dateRange[1]).format('YYYY-MM-DD'),
        childCustomerCode: childCustomerCode || undefined,
    });

    const data = {
        data: response?.data || [],
        isNextPresent: response?.isNextPresent,
    };

    if (response.isSuccess) {
        dispatch(setGenericData(bucket, data));
    } else {
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
