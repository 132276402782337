/* eslint-disable react/jsx-closing-tag-location */
import * as React from 'react';

const walletFaqList = [{
    question: 'What is the Virtual Wallet?',
    answer: <div>
        The Virtual Wallet is a financial tool designed to manage payments and transactions for Prepaid Customers.
        It helps streamline consignment payments, block and unblock amounts, and improve operational efficiency.
    </div>,
    answerText: `The Virtual Wallet is a financial tool designed to manage payments and transactions for Prepaid Customers.
    It helps streamline consignment payments, block and unblock amounts, and improve operational efficiency.`,
}, {
    question: 'How do I recharge the wallet?',
    answer: <div>
        Click the Recharge Button to add funds.
        Shipsy integrates with PayPhi for wallet recharges. More payment providers may be added in the future.
    </div>,
    answerText: `Click the Recharge Button to add funds.
    Shipsy integrates with PayPhi for wallet recharges. More payment providers may be added in the future.`,
}, {
    question: 'How does the recharge process work?',
    answer: <div>
        Once a recharge is made, the updated balance will be reflected in the wallet.
    </div>,
    answerText: 'Once a recharge is made, the updated balance will be reflected in the wallet.',
}, {
    question: 'What is the Wallet Ledger Transaction?',
    answer: <div>
        The Wallet Ledger Transaction tracks all debit and credit entries associated with the wallet.
        Reference Number is added means that entry is against the consignment else it is against the wallet of the customer.
    </div>,
    answerText: `The Wallet Ledger Transaction tracks all debit and credit entries associated with the wallet.
    Reference Number is added means that entry is against the consignment else it is against the wallet of the customer.`,
}, {
    question: 'What is the Blocked Ledger?',
    answer: <div>
        The Blocked Ledger tracks all block and unblock transactions against the consignments.
    </div>,
    answerText: 'The Blocked Ledger tracks all block and unblock transactions against the consignments.',
}, {
    question: 'What is the Recharge Ledger?',
    answer: <div>
        The Recharge Ledger logs all recharge transactions made by customers.
    </div>,
    answerText: 'The Recharge Ledger logs all recharge transactions made by customers.',
}, {
    question: 'How does the system manage amounts when a consignment is canceled?',
    answer: <div>
        If a consignment is canceled after an amount has been blocked, the system will automatically unblock the amount.
        However, if the amount has already been debited for the consignment,
        it will not be credited back if the consignment is canceled.
    </div>,
    answerText: `If a consignment is canceled after an amount has been blocked, 
    the system will automatically unblock the amount.
    However, if the amount has already been debited for the consignment, 
    it will not be credited back if the consignment is canceled.`,
}, {
    question: 'What happens when amounts are blocked at multiple statuses?',
    answer: <div>
        If amounts are blocked at multiple statuses, the system will unblock the difference if the final amount is lower
        or block additional amounts if the final amount is higher.
    </div>,
    answerText: `If amounts are blocked at multiple statuses,
     the system will unblock the difference if the final amount is lower
    or block additional amounts if the final amount is higher.`,
}, {
    question: 'How does the strategy to release "On Hold" consignments work?',
    answer: <div>
        The system can automatically release consignments marked
        &quot;On Hold&quot; due to insufficient balance based on the configured release strategy.
    </div>,
    answerText: `The system can automatically release consignments marked "On Hold"
     due to insufficient balance based on the configured release strategy.`,
}, {
    question: 'How do I track all wallet transactions?',
    answer: <div>
        Transactions are tracked in the following ledgers:
        <ul>
            <li>Wallet Ledger: Debit and credit entries.</li>
            <li>Blocked Ledger: Block and unblock entries.</li>
            <li>Recharge Ledger: Recharge transactions.</li>
        </ul>
    </div>,
    answerText: `Transactions are tracked in the following ledgers:
    - Wallet Ledger: Debit and credit entries.
    - Blocked Ledger: Block and unblock entries.
    - Recharge Ledger: Recharge transactions.`,
}];

export {
    walletFaqList,
};
