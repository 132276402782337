import * as React from 'react';
import { pickupStyles } from './pickup.styles';
// import Paginator from '../../common/paginator';
import moment from 'moment';
import {
    Button,
    DatePicker,
    message,
    Popover,
    Table,
    Modal,
} from 'antd';
import {
    InfoCircleOutlined,
    SettingOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    ExclamationCircleFilled,
    EditOutlined,
//   PrinterOutlined,
} from '@ant-design/icons';
import {
    bulkPrint,
} from '../../../network/consignments.api';
// import Helper from '../../../library/Helper';
import { HocOptions } from '../../common/generic-hoc.types';
import { StylesProps } from '../../../theme/jss-types';
import {
    downloadPickups,
    fetchSummaryPickups,
    markPickupAsSuccess,
    cancelCarrierPickups,
    getPickupConsignments,
} from '../../../network/pickup.api';
import PickupFilters from './pickup-filters';
import { BucketKeys, ReduxStore } from '../../../reducers/redux.types';
import { bindActionCreators } from 'redux';
import {
    applyFilters,
    setFilters,
} from '../../../actions/generic-action';
import { Buckets } from '../../../library/Types';
import { loadPickups } from '../../../actions/pickup-actions';
import GenericHoc from '../../common/generic-hoc';
import { getDownloadsRoute } from '../../../routing/routing-helper';
import { RouteChildrenProps } from 'react-router';
import { NAVBAR_HEIGHT } from 'library/globals';
import {
    Master,
    PickupBucketKeys,
    PickupColumn,
    PickupColumns,
} from '../../../types/master-data-types';
import PickupSettings from '../../settings/pickup-settings';
import { uniqBy } from 'lodash';
import { PickupBuckets, pickupConsignmentColumns } from './pickups.constants';
import { useTranslation } from 'react-i18next';
import GalleryModal from '../../common/gallery-modal';
import Helper from 'library/Helper';
import Loader from 'components/common/Loader';
import CreatePickup from '../create-pickup/create-pickup';
// import CreatePickup from '../create-pickup/create-pickup';

interface ConsignmentProps
    extends StylesProps<ReturnType<typeof pickupStyles>>,
    RouteChildrenProps {
    master: Master;
    setfilters: (bucketId: BucketKeys, filters: any) => void;
    applyfilters: (bucketId: BucketKeys) => void;
    filters: Record<string, any>;
    loading: boolean;
    loadpickups: () => void;
    pickups: any[];
    pickupCustomerConfig: any;
    success_pickup_creation: boolean;
    cancel_pickup_creation: boolean;
    edit_pickup_creation: boolean;
    defaultFilters: Record<string, any>;
    // uiTheme: ThemeType;
}
interface GalleryModalData {
    images: string[];
    videos: string[];
}

const {
    useState,
    useEffect,
} = React;

const PickupPage = (props: ConsignmentProps) => {
    const [bucketSelected, setBucketSelected] = useState<PickupBucketKeys>('customer_portal_all');
    const [moreFilterVisible, setMoreFiltersVisible] = useState<boolean>(false);
    const [summary, setSummary] = useState<any>({});
    const [downloading, setDownloading] = useState<boolean>(false);
    const [bulkPrinting, setBulkPrinting] = useState<boolean>(false);
    const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
    const [pickupConsignments, setPickupConsignments] = React.useState<string[] | null>(null);
    const [galleryModalData, setGalleryModalData] = React.useState<GalleryModalData>({ images: [], videos: [] });
    const [loadConsignments, setloadConsignments] = useState<boolean>(false);
    const [showConsignmentsModal, setShowConsignmentsModal] = useState<boolean>(false);
    const [actionModal, setActionModal] = React.useState<any>({});
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [editData, setEditData] = useState(null);
    const {
        classes,
        master,
        filters,
        setfilters,
        applyfilters,
        loadpickups,
        pickups,
        loading,
        history,
        defaultFilters,
        pickupCustomerConfig,
        success_pickup_creation,
        cancel_pickup_creation,
        edit_pickup_creation,
    } = props;
    const { t } = useTranslation();

    const { config } = master;

    const loadSummary = async () => {
        const response = await fetchSummaryPickups({
            dateFilterColumn: 'created_at',
            startDate: moment(filters.fromDate).format('YYYY-MM-DD'),
            endDate: moment(filters.toDate).format('YYYY-MM-DD'),
            bucketIds: Object.keys(PickupBuckets),
            isRiderAssigned: filters.isRiderAssigned === 'ALL' ? undefined : filters.isRiderAssigned === 'ASSIGNED',
            loadType: filters.courierType === 'ALL' ? undefined : filters.courierType,
        });
        if (response.isSuccess) {
            setSummary(response?.data || {});
        } else {
            message.error(response.errorMessage);
            setSummary({});
        }
    };
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    const showBucketCount = master?.config?.customer_portal_config?.fetch_customer_portal_bucket_count;

    useEffect(() => {
        loadpickups();
        loadSummary();
    }, [filters, toggle]);

    const renderText = (text: string | number | null) => {
        if (!text || text === null) {
            return <div className={classes.cellNa}>—na—</div>;
        }
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };
    const renderGallery = (imageArray: any[], videoArray: any[]) => {
        if (!imageArray || !videoArray) return 'Not Available';
        return (
            <a onClick={() => {
                setGalleryModalData({
                    images: imageArray,
                    videos: videoArray,
                });
                setIsGalleryModalVisible(true);
            }}
            >
                View
            </a>
        );
    };

    // const handlePrintLabel = (fileBuffer: any, fileName: string) => {
    //   if (fileBuffer.isSuccess) {
    //     const name = fileBuffer.filename || fileName;
    //     Helper.downloadFileData(
    //       fileBuffer.data,
    //       name,
    //       true,
    //     );
    //     message.success('Success');
    //   } else {
    //     message.error(fileBuffer.errorMessage);
    //   }
    // };

    // const handlePrint = async (row: any) => {
    //   const response = await bulkPrint({
    //     referenceNumbers: [row.reference_number],
    //   });
    //   handlePrintLabel(response, 'Print');
    // };

    // const renderDropdown = (row: any) => {
    //   return (
    //     <PrinterOutlined onClick={() => handlePrint(row)} />
    //   );
    // };

    const renderDestinationDetailsTable = (data: any) => {
        const columns = [
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: 'Units',
                dataIndex: 'units',
                key: 'units',
            },
        ];
        return (
            <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                style={{
                    margin: '0 10px',
                }}
            />
        );
    };

    const handlePickupEdit = (row: any) => {
        console.log('Edit Clicked', row);
        setEditData(row);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        setEditData(null);
    };

    const fetchPickupConsignment = async (row: any) => {
        setShowConsignmentsModal(true);
        setloadConsignments(true);
        const response = await getPickupConsignments({ taskId: row.taskId });
        if (response && response.isSuccess) {
            setPickupConsignments(Helper.isValidArray(response?.data) ? response?.data : []);
        } else {
            message.error(response.errorMessage);
            setPickupConsignments([]);
        }
        setloadConsignments(false);
    };

    const handleCloseModal = () => {
        setPickupConsignments(null);
        setShowConsignmentsModal(false);
    };

    const renderConsignmentDetails = (row: any) => {
        return (
            <>
                {row.totalItems}
                <Button type="link" onClick={() => fetchPickupConsignment(row)}>
                    <InfoCircleOutlined />
                </Button>
            </>
        );
    };

    const renderDestinationDetails = (row: any) => {
        return (
            <Popover
                trigger="click"
                title="Destination Details"
                content={() => renderDestinationDetailsTable(row.destinationDetails)}
            >
                <InfoCircleOutlined />
            </Popover>
        );
    };

    const getAddress = (address: any) => {
        let result = address.name;
        result += ', ';
        result += address.addressLine1;
        result += ', ';
        result += address.addressLine2;
        result += ', ';
        result += address.cityName;
        result += ', ';
        result += address.stateName;
        return result;
    };

    const handleActionModal = (pickupId: string, action: string) => {
        setActionModal({
            pickupId,
            action,
            isVisible: true,
        });
    };

    const handlePickupSuccess = async (pickupId: string) => {
        const response = await markPickupAsSuccess({
            pickupId,
        });
        if (response.isSuccess) {
            message.success('Pickup has been marked as Success');
        } else {
            message.error(response.errorMessage);
        }
    };

    const handlePickupCancel = async (pickupId: string) => {
        const response = await cancelCarrierPickups({
            pickupId,
        });
        if (response.isSuccess) {
            message.success('Pickup has been marked as Cancelled');
        } else {
            message.error(response.errorMessage);
        }
    };

    const renderColumn = (text: any, row: any, column: string) => {
        const blackListedStatusForActions = ['SUCCESS', 'CANCELLED'];
        switch (column) {
            case 'task_reference_number':
                return renderText(row.pickupId);
            case 'total_weight':
                return renderText(row.totalWeight);
            case 'total_volume':
                return renderText(row.totalVolume);
            case 'length':
                return renderText(row.length);
            case 'width':
                return renderText(row.width);
            case 'height':
                return renderText(row.height);
            case 'pickup_address':
                return renderText(getAddress(row.pickupAddress));
            case 'pickup_date':
                return renderText(row.timeSlotStart ? moment(row.timeSlotStart).format('DD MMM, YYYY') : null);
            case 'pickup_slot':
                return renderText(
                    row.timeSlotStart
                        ? `${moment(row.timeSlotStart).format('hh:mm')}-${moment(row.timeSlotEnd).format('hh:mm')}` : null,
                );
            case 'closing_time':
                return renderText(row.closingTimeString);
            case 'number_of_shipments':
                return renderConsignmentDetails(row);
            case 'item_type':
                return renderText(row.courierType);
            case 'pickup_task_status':
                return renderText(row.pickupTaskStatus);
            case 'is_rider_assigned':
                return renderText(row.isRiderAssigned ? 'Yes' : 'No');
            case 'waybill_type':
                return renderText(row.waybillType);
            case 'destination_details':
                return renderDestinationDetails(row);
            case 'failure_reason':
                return renderText(row.pickupFailureReason);
            case 'pickup_created_at':
                return renderText(row.pickupTaskCreationTimestamp);
            case 'pickup_pincode':
                return renderText(row.pickupAddress.pincode);
            case 'pickup_city':
                return renderText(row.pickupAddress.cityName);
            case 'pickup_phone_number':
                return renderText(row.pickupAddress.phone);
            case 'completion_time':
                return renderText(row.completionTime);
            case 'pickup_type':
                return renderText(row.pickupType);
            case 'service_type_id':
                return renderText(row.serviceTypeId);
            case 'courier_partner_pickup_number':
                return renderText(row.courierPartnerPickupNumber);
            case 'proof_of_pickup_image':
                if (!row
                    || !(row.taskProofOfPickupImage
                        || (Array.isArray(row.taskProofOfPickupImageList) && row.taskProofOfPickupImageList.length))) {
                    return renderText('Not Available');
                }
                return renderGallery([row.taskProofOfPickupImage, ...row.taskProofOfPickupImageList], []);
            case 'actions':
                if (
                    (
                        pickupCustomerConfig?.pickup_type?.length
                        && !pickupCustomerConfig?.pickup_type?.includes('Open Pickup')
                    )
                    || blackListedStatusForActions.includes(row.pickupTaskStatus)
                ) {
                    return renderText(text);
                }

                return (
                    <div className={classes.confirmationModal}>
                        {success_pickup_creation && (
                            <CheckCircleOutlined
                                onClick={() => {
                                    handleActionModal(row.pickupId, 'success');
                                    // handlePickupSuccess(row.pickupId);
                                }}
                            />
                        )}
                        {cancel_pickup_creation && (
                            <CloseCircleOutlined
                                onClick={() => {
                                    handleActionModal(row.pickupId, 'cancel');
                                    // handlePickupCancel(row.pickupId);
                                }}
                            />
                        )}
                        {edit_pickup_creation && (
                            <EditOutlined
                                onClick={() => {
                                    if (row.pickupTaskStatus === 'CREATED' || row.pickupTaskStatus === 'PENDING') {
                                        handlePickupEdit(row);
                                    }
                                }}
                                style={{
                                    color: (row.pickupTaskStatus !== 'CREATED' && row.pickupTaskStatus !== 'PENDING')
                                    || row.isRiderAssigned ? 'lightgray' : 'inherit',
                                    pointerEvents: (row.pickupTaskStatus !== 'CREATED' && row.pickupTaskStatus !== 'PENDING')
                                    || row.isRiderAssigned ? 'none' : 'auto',
                                }}
                            />
                        )}
                    </div>
                );
            default:
                return renderText(text);
        }
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'proof_of_pickup_image':
            case 'item_type': return 100;
            case 'completion_time':
            case 'pickup_created_at':
            case 'pickup_address':
                return 200;
            default: return 80;
        }
    };

    const getFixed = (column: string) => {
        switch (column) {
            case 'actions': return 'right';
            case 'task_reference_number': return 'left';
            default: return undefined;
        }
    };

    const getColumns = (): any[] => {
        const pickup_column_list_by_bucket: PickupColumns<any> = config?.pickup_column_list_by_bucket || {};
        const columnLabels = [...(pickup_column_list_by_bucket?.pickup_all || [])];
        columnLabels.push({
            pretty_name: t('actions'),
            column_id: 'actions',
        });

        const columns: any = columnLabels.map((column: PickupColumn) => {
            return {
                key: column.column_id,
                title: column.column_id === 'completion_time' ? t('completion_time_pickup') : t(column.column_id),
                dataIndex: column.column_id,
                width: getWidth(column.column_id),
                ellipsis: true,
                fixed: getFixed(column.column_id),
                render: (text: string, row: any) => renderColumn(text, row, column.column_id),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading}
                rowKey={(row) => row.id}
                columns={getColumns()}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                className={classes.table}
                dataSource={pickups || []}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 150px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    const handleBucketChange = (bucket: PickupBucketKeys) => {
        if (bucket === bucketSelected) {
            return;
        }
        setfilters(Buckets.PICKUPS, {
            ...filters,
            status: bucket === 'customer_portal_all' ? undefined : bucket.substring(16),
        });
        applyfilters(Buckets.PICKUPS);
        setBucketSelected(bucket);
    };

    const renderBucket = (bucket: PickupBucketKeys) => {
        if (bucket === 'customer_portal_unresolved') {
            return null;
        }
        let bucketClass = classes.bucket;
        let countClass = classes.count;
        if (bucket === bucketSelected) {
            bucketClass = [classes.bucketSelected, classes.bucket].join(' ');
            countClass = [classes.count, classes.countSelected].join(' ');
        }
        return (
            <div
                key={bucket}
                className={bucketClass}
                onClick={() => handleBucketChange(bucket)}
            >
                <span
                    className={classes.bucketName}
                >
                    {t(bucket)}
                </span>
                {
                    showBucketCount
                        ? (<span className={countClass}>{summary[bucket] || 0}</span>)
                        : null
                }
            </div>
        );
    };

    const renderBuckets = () => {
        return (
            <div className={classes.bucketRow}>
                {Object.keys(PickupBuckets)
                    .map((bucket) => renderBucket(bucket as PickupBucketKeys))}
            </div>
        );
    };

    const renderDateField = (key: 'fromDate' | 'toDate') => {
        const name = key === 'fromDate' ? 'From' : 'To';
        const inputName = t(`${name}_date`);
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    {inputName}
                </span>
                <DatePicker
                    value={moment(filters[key])}
                    allowClear={false}
                    placeholder={`Select ${inputName}`}
                    onChange={(value) => {
                        const newFilters = {
                            ...filters,
                        };
                        if (key === 'fromDate') {
                            if (moment(filters.toDate).diff(value, 'days') > 30) {
                                const toDate = moment(value).add(30, 'd');
                                newFilters.toDate = toDate.unix() * 1000;
                                newFilters.fromDate = moment(value).unix() * 1000;
                            } else if (moment(filters.toDate).diff(value, 'days') < 0) {
                                newFilters.toDate = moment(value).unix() * 1000;
                                newFilters.fromDate = moment(value).unix() * 1000;
                            } else {
                                newFilters[key] = moment(value).unix() * 1000;
                            }
                        } else {
                            newFilters[key] = moment(value).unix() * 1000;
                        }
                        setfilters(Buckets.PICKUPS, newFilters);
                        applyfilters(Buckets.PICKUPS);
                    }}
                />
            </div>
        );
    };

    const isMoreFilterAdded = () => {
        const dateKeys = ['fromDate', 'toDate'];
        const isFilterApplied = Object.keys(filters).some((filter) => {
            if (dateKeys.includes(filter)) {
                return false;
            }
            return filters[filter] !== defaultFilters[filter];
        });
        return isFilterApplied;
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderDateField('fromDate')}
                {renderDateField('toDate')}
            </div>
        );
    };

    const handleSettingsClose = (refresh?: boolean) => {
        setSettingsVisible(false);
        if (refresh) {
            setToggle(!toggle);
        }
    };
    const renderSettingsModal = () => {
        if (!settingsVisible) {
            return null;
        }
        const options = uniqBy(config?.full_pickup_column_list || [], 'column_id');
        const columns: Record<string, PickupColumn> = {};
        options.forEach((item) => {
            columns[item.column_id] = item;
        });
        return (
            <PickupSettings
                bucketSelected="pickup_all"
                filterObject={{ columns }}
                onClose={(refresh?: boolean) => handleSettingsClose(refresh)}
            />
        );
    };

    const getFilterValue = (value: string) => {
        if (value === 'ALL') {
            return undefined;
        }
        return value;
    };

    const getRiderAssigned = (value: string) => {
        if (value === 'ALL') {
            return undefined;
        }
        return value === 'ASSIGNED';
    };

    const handleDownload = async () => {
        const dateFormat = 'DD/MM/YYYY';
        setDownloading(true);
        const response = await downloadPickups({
            clientName: 'ALL',
            courierName: 'ALL',
            fromDate: moment(filters.fromDate).format(dateFormat),
            toDate: moment(filters.toDate).format(dateFormat),
            sortBy: filters.sortBy,
            courierType: getFilterValue(filters.courierType),
            isRiderAssigned: getRiderAssigned(filters.isRiderAssigned),
            status: getFilterValue(filters.status),
        });
        if (response.isSuccess) {
            message.success('Downloaded Successfully');
            history.push(getDownloadsRoute());
        } else {
            message.error(response.errorMessage);
        }
        setDownloading(false);
    };

    const renderDownload = () => {
        return (
            <Button
                type="ghost"
                loading={downloading}
                className={classes.downloadBtn}
                onClick={handleDownload}
            >
                {t('download')}
            </Button>
        );
    };

    const handleBulkPrint = async () => {
        setBulkPrinting(true);
        const response = await bulkPrint({});
        if (response.isSuccess) {
            message.success('Success');
        } else {
            message.error(response.errorMessage);
        }
        setBulkPrinting(false);
    };

    const renderPrint = () => {
        return (
            <Button
                type="primary"
                style={{ display: 'none' }}
                className={classes.downloadBtn}
                loading={bulkPrinting}
                onClick={handleBulkPrint}
            >
                {t('bulk_print')}
            </Button>
        );
    };

    // const renderPaginator = () => {
    //   return (
    //     <Paginator
    //       currentPageNumber={1}
    //       isNextPresent={false}
    //       onNextClick={() => { }}
    //       onPrevClick={() => { }}
    //     />
    //   );
    // };

    const renderSettings = () => {
        return (
            <SettingOutlined
                className={classes.settingIcon}
                onClick={() => setSettingsVisible(true)}
            />
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderDownload()}
                {renderPrint()}
                {renderSettings()}
                {/* {renderPaginator()} */}
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                {renderBuckets()}
                {renderExtraFilters()}
            </div>
        );
    };

    const renderMoreFilters = () => {
        if (!moreFilterVisible) {
            return null;
        }
        return (
            <PickupFilters
                resetVisible={isMoreFilterAdded()}
                onClose={() => setMoreFiltersVisible(false)}
            />
        );
    };

    const renderConsignmentDetailsForPickup = () => {
        return (
            <Modal
                title="Consignments"
                width={400}
                centered
                visible={showConsignmentsModal}
                onCancel={handleCloseModal}
                footer={null}
            >
                <Table
                    dataSource={(pickupConsignments || []).map((referenceNumber: string, idx: number) => ({
                        referenceNumber,
                        serialNumber: idx + 1,
                    }))}
                    rowKey={(row) => row.referenceNumber}
                    loading={loadConsignments}
                    columns={pickupConsignmentColumns}
                    pagination={false}
                />
            </Modal>
        );
    };

    const handleModalSubmit = async (shouldPerformAction: boolean) => {
        if (!shouldPerformAction) {
            setActionModal({
                action: null,
                pickupId: null,
                isVisible: false,
            });
            return;
        }
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        if (actionModal.action === 'success') {
            await handlePickupSuccess(actionModal.pickupId);
        } else if (actionModal.action === 'cancel') {
            await handlePickupCancel(actionModal.pickupId);
        }
        setIsLoading(false);
        setActionModal({
            action: null,
            pickupId: null,
            isVisible: false,
        });
    };

    const renderCloseConfirmModal = (shouldLoad: boolean, actionModalConfig: any) => {
        const { action, isVisible } = actionModalConfig;

        return (
            <Modal
                title={(
                    <div style={{ fontWeight: 600 }}>
                        <ExclamationCircleFilled style={{ color: '#EA2626', fontSize: 16 }} />
                        &nbsp;
                        {t('Confirm Action')}
                    </div>
                )}
                visible={isVisible}
                onCancel={() => handleModalSubmit(false)}
                footer={[
                    <Button
                        key="back"
                        type="text"
                        onClick={() => handleModalSubmit(false)}
                        disabled={shouldLoad}
                    >
                        {t('No')}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        disabled={shouldLoad}
                        style={{ padding: shouldLoad ? '4px 20px' : '4px 15px' }}
                        onClick={() => handleModalSubmit(true)}
                    >
                        { shouldLoad ? <Loader zIndex={1500} size="16" /> : t('Yes') }
                    </Button>,
                ]}
            >
                {`Are you sure you want to mark this pickup as ${action}?`}
            </Modal>
        );
    };
    const handleSuccess = (data:any) => {
        // Handle the success event, e.g., show a success message
        console.log('Pickup created successfully:', data);
        // Close the drawer
        closeDrawer();
    };

    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
            {renderMoreFilters()}
            {renderSettingsModal()}
            {isGalleryModalVisible && (
                <GalleryModal
                    isVisible={isGalleryModalVisible}
                    imageVideoGalleryData={galleryModalData}
                    handleModalClose={() => { setIsGalleryModalVisible(false); }}
                />
            )}
            {renderConsignmentDetailsForPickup()}
            {renderCloseConfirmModal(isLoading, actionModal)}
            {drawerVisible && (
                <CreatePickup
                    config={master?.config}
                    editPickupData={editData}
                    allowEdit
                    visible={drawerVisible}
                    loadpickups={loadpickups}
                    onClose={closeDrawer}
                    setSuccess={handleSuccess}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic, master, uiTheme } = state;
    const { PICKUPS } = generic;
    const pickupCustomerConfig = master?.config?.customer_portal_ops_config?.pickup_configuration?.config_value || {};
    const customerProperties = master?.properties;
    const {
        appliedFilters,
        loading,
        data,
        defaultFilters,
    } = PICKUPS;
    return {
        master,
        loading,
        pickupCustomerConfig,
        defaultFilters,
        pickups: data,
        filters: appliedFilters,
        uiTheme,
        success_pickup_creation: customerProperties?.success_pickup_creation,
        cancel_pickup_creation: customerProperties?.cancel_pickup_creation,
        edit_pickup_creation: customerProperties?.edit_pickup_creation,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setfilters: setFilters,
        applyfilters: applyFilters,
        loadpickups: loadPickups,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: pickupStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const Pickups = GenericHoc(hocConfig)(PickupPage);
export default Pickups;
