import * as React from 'react';
import { StylesProps } from '../../../../theme/jss-types';
import { FormField } from '../../create-consignment.types';
import { formFields } from '../../create-modal.constants';
import { scheduleStepStyles } from '../../single-consignment.styles';
import {
    Checkbox,
    Col,
    Form,
    FormInstance,
    Row,
} from 'antd';
import { Service } from '../../../../library/Types';
import Loader from '../../../common/Loader';
import { getBusinessTat, getInternationalBusinessTatV2, getServiceabilityRateTat } from '../../../../network/common.api';
import { useTranslation } from 'react-i18next';
import Helper from 'library/Helper';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { ReduxStore } from 'reducers/redux.types';
import { CustomerPortalConfig } from 'types/master-data-types';
import { convertToAPIBodySaveSingle } from '../single-consignment-helper';
import { formRules } from 'library/constants';

const {
    useState,
    useEffect,
} = React;
interface IProps extends StylesProps<ReturnType<typeof scheduleStepStyles>> {
    form: FormInstance;
    params: any;
    showRiskSurchargeType: boolean;
    enableServiceability: boolean;
    allowedConsignmentFormType: any;
}

const ServiceTypeSelectorField = (props: IProps) => {
    const {
        classes,
        form,
        params,
        enableServiceability,
        showRiskSurchargeType,
        allowedConsignmentFormType,
    } = props;
    const {
        Services,
        // ConsignmentCategory,
    } = formFields;
    const { t, i18n } = useTranslation();
    const [selectedService, setSelected] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [services, setServices] = React.useState<any[]>([]);
    // const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const isEditingCN = Form.useWatch('isEditingCN', form);
    const { isCurrentTab } = params;

    const loadServices = async () => {
        setLoading(true);
        const storage = window.localStorage;
        const formData = form.getFieldsValue();

        const commonParts = {
            srcPincode: formData.srcAddress?.pincode,
            srcStateName: formData.srcAddress?.stateName,
            dstStateName: formData.dstAddress?.stateName,
            dstPincode: formData.dstAddress?.pincode,
            countryName: formData.dstAddress?.countryName,
            clientCode: storage.getItem('userCode'),
            width: formData.width,
            hight: formData.hight,
            length: formData.length,
            weight: formData.weight || 1,
            numPieces: formData?.numberOfPieces,
            referenceNumber: formData.referenceNumber,
            courierType: formData.courierType?.toUpperCase(),
            is_international: formData.is_international,
            pieceDetails: formData?.courierType === 'non-document'
                ? [{
                    length: Helper.converttoCentimeter(formData?.length, formData?.unit),
                    width: Helper.converttoCentimeter(formData?.width, formData?.unit),
                    height: Helper.converttoCentimeter(formData?.height, formData?.unit),
                }] : undefined,
        };
        if (formData.consignmentCategory === 'international') {
            let commodityCode;
            formData?.contentList?.forEach((commodity: any) => {
                if (formData?.commodityId && formData?.commodityId[0] === commodity?.COMMODITY_ID) {
                    commodityCode = commodity?.COMMODITY_CODE;
                }
            });
            const response = await getInternationalBusinessTatV2({
                commodityCode,
                dstCountry: formData?.dstAddress?.countryName,
                dstCity: formData?.dstAddress?.cityName,
                srcCity: formData?.srcAddress?.cityName,
                ...commonParts,
            });
            setServices(response?.data?.map((service: any) => {
                return {
                    ...service,
                    serviceType: service.serviceType,
                    serviceTypeId: service.serviceType,
                };
            }) || []);
        } else {
            let response:any;
            if (enableServiceability) {
                response = await getServiceabilityRateTat(convertToAPIBodySaveSingle({
                    ...formData,
                }, {
                    showRiskSurchargeType,
                    source: 'CUSTOMER_PORTAL_SINGLE_V2',
                }));
            } else {
                response = await getBusinessTat(commonParts);
            }
            setServices(response?.data || []);
        }
        setLoading(false);
    };

    const prefillData = () => {
        // form.setFieldsValue({
        //     [Services.key]: formData[Services.key],
        // });
        setSelected(form.getFieldValue(Services.key));
    };

    useEffect(() => {
        if (isCurrentTab) {
            loadServices();
            prefillData();
        }
    }, [isCurrentTab]);

    const renderTitle = (field: FormField) => {
        return (
            <div
                className={classes.referenceTitle}
            >
                {t(field.key)}
            </div>
        );
    };

    function handleSelection(item: Service) {
        if (selectedService === item.serviceTypeId) {
            setSelected(undefined);
            form.setFieldsValue({
                [Services.key]: undefined,
            });
        } else {
            setSelected(item.serviceTypeId);
            form.setFieldsValue({
                [Services.key]: item.serviceTypeId,
            });
        }
    }

    const getCheckboxClass = (item: Service) => {
        const classList = [classes.checkbox, classes.checkboxBody];
        if (selectedService === item.serviceTypeId) {
            classList.push(classes.checkboxSelected);
        }
        return classList.join(' ');
    };

    const renderServiceName = (item: Service) => {
        return (
            <div className={classes.serviceItem}>
                {item.serviceType}
            </div>
        );
    };

    const renderTat = (item: Service) => {
        const tat = item?.period || item?.tat || item?.TAT;
        if (!tat) {
            return <div className={classes.tat} />;
        }
        return (
            <div className={classes.tat}>
                {tat.toString().endsWith('Days') ? tat : `${tat} Days`}
            </div>
        );
    };

    const renderRate = (item: Service) => {
        if (!item.rate?.amount) {
            return <div className={classes.FinalPrice} />;
        }
        return (
            <div className={classes.FinalPrice}>
                {`${item.rate?.amount}`}
            </div>
        );
    };

    const renderDeliveryTime = (item: Service) => {
        if (!item.deliveryTime) {
            return <div className={classes.FinalPrice} />;
        }
        return (
            <div className={classes.FinalPrice}>
                {item.deliveryTime}
            </div>
        );
    };

    // const checkedStyle = {
    //     color: '#27B479',
    //     fontSize: 12,
    // };

    // const outlined = {
    //     color: '#EA2626',
    //     fontSize: 12,
    // };

    // const renderCodServiceable = (item: Service) => {
    //     if (typeof (item.isCodServiceable) !== 'string') {
    //         return <div className={classes.codService} />;
    //     }
    //     return (
    //         <div className={classes.codService}>
    //             <span
    //                 className={classes.serviceableTitle}
    //             >
    //                 COD Serviceable
    //             </span>
    //             {item.isCodServiceable && <CheckCircleFilled style={checkedStyle} />}
    //             {!item.isCodServiceable && <CloseCircleOutlined style={outlined} />}
    //         </div>
    //     );
    // };

    // const renderLiteServiceable = (item: Service) => {
    //     if (typeof (item.isLiteServiceable) !== 'string') {
    //         return <div className={classes.codService} />;
    //     }
    //     return (
    //         <div className={classes.codService}>
    //             <span
    //                 className={classes.serviceableTitle}
    //             >
    //                 LITE Serviceable
    //             </span>
    //             {item.isLiteServiceable && <CheckCircleFilled style={checkedStyle} />}
    //             {!item.isLiteServiceable && <CloseCircleOutlined style={outlined} />}
    //         </div>
    //     );
    // };

    const renderService = (item: Service) => {
        return (
            <Row>
                <Col span={12}>
                    <Checkbox
                        name={item.serviceTypeId}
                        key={item.serviceTypeId}
                        checked={item.serviceTypeId === selectedService}
                        onClick={() => handleSelection(item)}
                        className={getCheckboxClass(item)}
                        disabled={isEditingCN}
                    >
                        {renderServiceName(item)}
                    </Checkbox>
                </Col>

                {
                    allowedConsignmentFormType.includes('Rate')
                        ? <Col span={4} className={classes.column}>{renderRate(item)}</Col>
                        : null
                }
                {
                    allowedConsignmentFormType.includes('TAT')
                        ? <Col span={4} className={classes.column}>{renderTat(item)}</Col>
                        : null
                }
                {
                    allowedConsignmentFormType.includes('Delivery Time')
                        ? <Col span={4} className={classes.column}>{renderDeliveryTime(item)}</Col>
                        : null
                }
                {/* {consignmentCategory === 'international' ? null : renderCodServiceable(item)}
                {consignmentCategory === 'international' ? null : renderLiteServiceable(item)} */}
            </Row>
        );
    };

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const renderCheckbox = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                style={{ maxHeight: '150px', overflowY: 'scroll' }}
                rules={[formRuleRequired]}
            >
                {services?.map((item) => renderService(item))}
            </Form.Item>
        );
    };

    const renderLine = () => {
        return (
            <div className={classes.line} />
        );
    };

    const renderHeaders = () => {
        return (
            <Row>
                <Col span={10} offset={2} className={classes.serviceItem}>
                    Service Type
                </Col>
                {allowedConsignmentFormType.includes('Rate') && (
                    <Col span={4} className={classes.FinalPrice}>
                        Rate
                    </Col>
                )}
                {allowedConsignmentFormType.includes('TAT') && (
                    <Col span={4} className={classes.tat}>
                        TAT
                    </Col>
                )}
                {allowedConsignmentFormType.includes('Delivery Time') && (
                    <Col span={4} className={classes.FinalPrice}>
                        Delivery Time
                    </Col>
                )}
            </Row>
        );
    };

    const renderServicesForm = () => {
        return (
            <div className={classes.boxFields}>
                {renderTitle(Services)}
                {renderLine()}
                {renderHeaders()}
                {renderCheckbox(Services)}
            </div>
        );
    };

    const renderServices = () => {
        return (
            <div className={classes.box}>
                {renderServicesForm()}
            </div>
        );
    };

    return (
        <Col span={24}>
            <div>
                {loading && <Loader zIndex={10} />}
                {renderServices()}
            </div>
        </Col>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const customerPortalConfig:CustomerPortalConfig = state?.master?.config?.customer_portal_config;
    const allowedConsignmentFormType = state?.master?.config?.customer_portal_ops_config
        ?.consignment_flow_type_config?.config_value?.allowed_consignment_form_type_customer_portal || [];
    return {
        allowedConsignmentFormType,
        showRiskSurchargeType: customerPortalConfig?.show_risk_surcharge_type,
        enableServiceability: customerPortalConfig?.enable_serviceability,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: scheduleStepStyles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(ServiceTypeSelectorField);
