export const purchaseOrderColumns: Record<string, string> = {
    reference_number: 'PO #',
    customer_reference_number: 'Customer Reference Number',
    supplier_name: 'Supplier Name',
    description: 'Description',
    open_quantity: 'Quantity',
    type: 'Type',
    created_at: 'Created At',
    order_due_date: 'Order Due Date',
    expected_delivery_date: 'Expected Delivery Date ',
    notes: 'Notes',
};

export const detailKeys = [
    'reference_number',
    'customer_reference_number',
    'description',
    'type',
    'open_quantity',
    'order_date',
    'order_due_date',
    'expected_delivery_date',
    'notes',
    'origin_country',
    'destination_country',
    'payment_terms',
    'inco_terms',
    'group_code',
    'buyer_code',
    'supplier_name',
];

export const pieceKeys = [
    'product_code',
    'reference_number',
    'quantity',
    'expected_delivery_date',
    'order_due_date',
];

export const pieceDetailKeys = [
    'customer_reference_number',
    'description',
    'item_unit',
    'unit_price',
    'unit_cost',
    'weight',
    'volume',
    'length',
    'width',
    'height',
    'hs_code',
    'source_location',
    'is_dangerous_good',
    'alternate_unit',
    'stock_number',
    'is_chemical',
];
