const WalletPageOptions = {
    WALLET_LEDGER: 'Virtual Wallet Ledger Statement',
    BLOCKED_AMOUNT: 'Blocked Amount Ledger',
    RECHARGE_TRANSACTIONS: 'Recharge Transactions',
};

const blockedAmountColumns = {
    created_at: 'Transaction Date and Time',
    transaction_type: 'Transaction Type',
    reference_number: 'Reference No.',
    amount: 'Amount',
};

const walletLedgerColumns = {
    created_at: 'Transaction Date and Time',
    transaction_type: 'Transaction Type',
    reference_number: 'Reference No.',
    notes: 'Transaction Notes',
    wallet_balance: 'Balance',
    amount: 'Amount',
};

const rechargeTransactionColumns = {
    created_at: 'Transaction Date and Time',
    amount: 'Amount',
    status: 'Status',
};

const walletColumns = {
    WALLET_LEDGER: walletLedgerColumns,
    BLOCKED_AMOUNT: blockedAmountColumns,
    RECHARGE_TRANSACTIONS: rechargeTransactionColumns,
};

export {
    walletColumns,
    WalletPageOptions,
};
