import moment from 'moment';
import { DefaultState, InitialValue } from './redux.types';
import { filterConfig } from 'library/globals';

const storage = window.localStorage;
const orgId: string = storage.getItem('organisationId') as string;
const defaultFilters = filterConfig[orgId] || {};

const consignmentFilters = {
    ...defaultFilters,
    bucketSelected: 'customer_portal_all',
    startDate: moment().subtract(7, 'd').unix() * 1000,
    endDate: moment().unix() * 1000,
    sortBy: 'created_at',
    descendingOrder: true,
};
const consignmentInitial: InitialValue = {
    data: [],
    loading: false,
    filters: consignmentFilters,
    pagination: {
        resultPerPage: 20,
        currentPageNumber: 1,
    },
    summary: {},
    appliedFilters: consignmentFilters,
    defaultFilters: consignmentFilters,
};

const pluginFilters = {
    startDate: moment().subtract(7, 'd').unix() * 1000,
    endDate: moment().unix() * 1000,
    ConsignmentType: 'Domestic',
};

const pluginInitial: InitialValue = {
    data: [],
    loading: false,
    filters: pluginFilters,
    pagination: {
        resultPerPage: 20,
        currentPageNumber: 1,
    },
    summary: {},
    appliedFilters: pluginFilters,
    defaultFilters: pluginFilters,
};

const pickupFilters = {
    isRiderAssigned: 'ALL',
    courierType: 'ALL',
    status: 'ALL',
    fromDate: moment().unix() * 1000,
    toDate: moment().add(7, 'd').unix() * 1000,
};
const pickupInitial: InitialValue = {
    data: [],
    loading: false,
    summary: {},
    filters: pickupFilters,
    pagination: {},
    appliedFilters: pickupFilters,
    defaultFilters: pickupFilters,
};

const isParentCustomer = JSON.parse(window.localStorage.getItem('parentId') as string);
const asnFilters:any = {
    bucketSelected: isParentCustomer ? 'customer_portal_asn_assigned' : 'customer_portal_asn_uploaded',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    uploadStartDate: moment().format('YYYY-MM-DD'),
    uploadEndDate: moment().format('YYYY-MM-DD'),
    selectDestinationLocationCode: '',
    isAsnDateApplied: false,
    sortBy: 'created_at',
    descendingOrder: true,
    asnNumberList: [],
};
if (!isParentCustomer) {
    asnFilters.vendorCodeIdList = [];
}

const asnInitial: InitialValue = {
    data: [],
    loading: false,
    filters: asnFilters,
    pagination: {
        resultPerPage: 20,
        currentPageNumber: 1,
    },
    summary: {},
    appliedFilters: asnFilters,
    defaultFilters: asnFilters,
};

const addressInitial: InitialValue = {
    data: [],
    loading: false,
    summary: {},
    filters: [],
    pagination: {
        resultPerPage: 20,
        currentPageNumber: 1,
    },
    appliedFilters: {},
    defaultFilters: {},
};

const rtoInitial: InitialValue = {
    data: [],
    loading: false,
    summary: {},
    filters: [],
    pagination: {
        currentPageNumber: 1,
    },
    appliedFilters: {},
    defaultFilters: {},
};

const purchaseOrderInitial: InitialValue = {
    data: [],
    loading: false,
    filters: [],
    pagination: {
        resultPerPage: 20,
        currentPageNumber: 1,
    },
    appliedFilters: {},
    defaultFilters: {},
    summary: {},
};

const remittanceFilters = {
    fromDate: moment().subtract(1, 'months').startOf('month').unix(),
    toDate: moment().unix(),
};

const remittanceInitial: InitialValue = {
    data: [],
    loading: false,
    filters: remittanceFilters,
    pagination: {
        currentPageNumber: 1,
    },
    appliedFilters: remittanceFilters,
    summary: {},
    defaultFilters: remittanceFilters,
};

const invoiceFilters = {
    date: moment().startOf('month').unix(),
    sbu: 'CTB',
};

const invoiceInitial: InitialValue = {
    data: [],
    loading: false,
    filters: invoiceFilters,
    pagination: {
        currentPageNumber: 1,
    },
    appliedFilters: invoiceFilters,
    summary: {},
    defaultFilters: invoiceFilters,
};

const suppServicesFilters = {
    fromDate: moment().subtract(7, 'days').unix() * 1000,
    toDate: moment().unix() * 1000,
};

const suppServicesInitial: InitialValue = {
    data: [],
    loading: false,
    filters: suppServicesFilters,
    pagination: {
        currentPageNumber: 1,
    },
    appliedFilters: suppServicesFilters,
    summary: {},
    defaultFilters: suppServicesFilters,
};

export const defaultGenericState: DefaultState = {
    CONSIGNMENTS: consignmentInitial,
    PICKUPS: pickupInitial,
    ASN: asnInitial,
    ADDRESSES: addressInitial,
    RTO_DASHBOARD: rtoInitial,
    REMITTANCE: remittanceInitial,
    INVOICE: invoiceInitial,
    PLUGIN: pluginInitial,
    SUPP_SERVICES: suppServicesInitial,
    PURCHASE_ORDER: purchaseOrderInitial,
};
