import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Select,
} from 'antd';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>> {
    params: any;
    form: FormInstance;
}

const ConsignmentAccountField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const { classes, params, form } = props;
    const { SubAccount } = formFields;
    const { childClients, isRequired } = params;
    const isEditingCN = Form.useWatch('isEditingCN', form);

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const optionList = childClients?.map((item: any) => {
        return {
            value: `${item.customer_code}+${item.customer_id}+${item.customer_name}`,
            label: item.customer_name,
        };
    });
    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div
                    className={classes.title}
                >
                    {t(SubAccount.key)}
                </div>
                <Form.Item
                    name={SubAccount.key}
                    className={classes.contentType}
                    initialValue={SubAccount.defaultValue}
                    valuePropName={SubAccount.valuePropName}
                    rules={isRequired(false, SubAccount.key) ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={SubAccount.placeholder}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        disabled={isEditingCN}
                        options={optionList}
                        filterOption={(query, option: any) => {
                            return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                        }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = () => ({});

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(ConsignmentAccountField);
