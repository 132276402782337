import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Row,
    FormInstance,
    Button,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import { itemFormFields } from 'components/create-consignment/create-modal.constants';
import GenericField from './generic-field';
import { uniqueId } from 'lodash';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    formItemWeight: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
});

const {
    TaxPercentage,
    TotalTax,
    ManufacturingCountry,
} = itemFormFields;

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
}

const GenericItemField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        params,
        form,
        piecesItemSubLayout,
    } = props;

    const {
        id: pieceIndex,
        setItemIndices,
        itemIndices,
    } = params;

    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;

    if (!isPieceLevel) return <></>;

    const getItemFieldFromMapping = (id: any, itemIndex: any) => {
        const parentObj = `piecesItem_${pieceIndex}`;
        const parameters = { ...params, itemIndex, key: undefined };

        const getMapping = (
            id_: any,
            Component: any,
        ) => {
            if (Component) {
                return <Component form={form} params={params} shouldNotMakeItemTypeCheck="true" />;
            }
            console.log('Cannot find component for ', id_);
            return null;
        };

        const getPieceItemDetailsFieldMapping = {
            [TaxPercentage.key]: () => {
                return (
                    <GenericField
                        params={parameters}
                        form={form}
                        parentObj={parentObj}
                        field={TaxPercentage}
                        MAX_LIMIT={100}
                    />
                );
            },
            [TotalTax.key]: () => {
                return (
                    <GenericField params={parameters} form={form} parentObj={parentObj} field={TotalTax} />
                );
            },
            [ManufacturingCountry.key]: () => {
                return (
                    <GenericField params={parameters} form={form} parentObj={parentObj} field={ManufacturingCountry} />
                );
            },
        };
        return getMapping(id, getPieceItemDetailsFieldMapping[id]);
    };

    const addItem = () => {
        const newItemIndex = (itemIndices[pieceIndex] || []).length;
        const newItemIndices = itemIndices;
        newItemIndices[pieceIndex] = [...(itemIndices[pieceIndex] || []), newItemIndex];
        setItemIndices([...newItemIndices]);
    };

    const removeItem = (index: number) => {
        const reqPieceItemObj = `piecesItem_${pieceIndex}`;
        const reqPiecesItem = form.getFieldValue(reqPieceItemObj);
        if (reqPiecesItem && reqPiecesItem.length && reqPiecesItem[index]) {
            reqPiecesItem.splice(index, 1);
            form.setFieldsValue({ reqPiecesItem });
        }
        if (itemIndices[pieceIndex] && itemIndices[pieceIndex].length >= index) {
            itemIndices[pieceIndex].splice(index, 1);
        }
        const indexArr = [];
        for (let i = 0; i < (itemIndices[pieceIndex] || []).length; i += 1) {
            if (i >= index) {
                indexArr.push(itemIndices[pieceIndex][i] - 1);
            } else {
                indexArr.push(i);
            }
        }
        const newItemIndices = itemIndices;
        newItemIndices[pieceIndex] = [...indexArr];
        setItemIndices([...newItemIndices]);
    };

    const renderRemoveItem = (index: number) => {
        return (
            <Button danger type="link" onClick={() => removeItem(index)}>{t('remove')}</Button>
        );
    };

    const itemComponent = (
        <>
            {itemIndices[pieceIndex]?.map((itemIndex: number) => {
                const itemForm = piecesItemSubLayout?.map(
                    (fieldKey: any) => getItemFieldFromMapping(fieldKey, itemIndex),
                );
                return (
                    <div
                        key={itemIndex}
                        style={{
                            marginBottom: '5px',
                            padding: '10px',
                            backgroundColor: '#fafafa',
                            borderRadius: '5px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <strong
                                style={{ fontSize: 15, paddingTop: '10px', paddingBottom: '10px' }}
                            >
                                {'Item '}
                                {itemIndex + 1}
                            </strong>
                            {itemIndices[pieceIndex]?.length !== 1 && renderRemoveItem(itemIndex)}
                        </div>
                        <Row gutter={10}>
                            {itemForm}
                        </Row>
                    </div>
                );
            })}
        </>
    );

    const renderAddMoreButton = () => {
        if (true) {
            return (
                <Button
                    type="primary"
                    onClick={addItem}
                    style={{ margin: '10px 0' }}
                >
                    {t('add_more_items')}
                </Button>
            );
        }
        return <></>;
    };

    return (
        <Col span={24}>
            {piecesItemSubLayout?.length > 0 && (
                <div
                    key={uniqueId() + (+new Date())}
                >
                    {itemComponent}
                    {renderAddMoreButton()}
                </div>
            )}
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const subLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const piecesItemSubLayout = subLayout?.piecesItem || [];
    return {
        piecesItemSubLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(GenericItemField);
